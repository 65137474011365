import { useTranslation } from 'react-i18next';

import { Divider } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import { ContentaButtonPrimaryOutline } from 'components/Styled/ContentaButton';

import { StyledRegisterCompleted } from '../styles';

function AlreadyRegistered() {
  const { t } = useTranslation();
  return (
    <StyledRegisterCompleted>
      <h1>{t('register.successRegistered')}</h1>

      <p>{t('register.successRegisteredMessage')}</p>
      <p>{t('register.successRegisteredMessage2')}</p>
      <p>{t('register.successRegisteredMessage3')}</p>

      <Divider />

      <a href="/login" className="w-100">
        <ContentaButtonPrimaryOutline type="link" className="w-100">
          <LeftOutlined />
          {t('common.back')}
        </ContentaButtonPrimaryOutline>
      </a>
    </StyledRegisterCompleted>
  );
}

export default AlreadyRegistered;
