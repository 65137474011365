import { ThemeProvider } from 'styled-components';

import { AppWrapper } from '../AppWrapper';

import { SearchProvider } from 'contexts/searchContext';
import { LanguageProvider } from '../../contexts/languageContext';
import { UserProvider } from '../../contexts/userContext';

import GlobalStyle from '../../styles/globalStyles';
import defaultTheme from '../../styles/themes/default';

import { CONTENTA_REDIRECT } from 'constants/storages-values';

const urlsToNotSave = [
  '/',
  '/faq',
  '/guide/register',
  '/login',
  '/login/advisor',
  '/meet/*',
  '/privacy-policy',
  '/redirecting',
  '/register',
  '/terms-and-conditions',
];
const hasContentaRedirect = sessionStorage.getItem(CONTENTA_REDIRECT);

function App() {
  const localStorageLanguage = sessionStorage.getItem('@contenta-language');
  const { pathname, search } = window.location;

  if (
    (pathname.includes('/advisor-details/') ||
      !urlsToNotSave.includes(pathname)) &&
    !hasContentaRedirect
  ) {
    sessionStorage.setItem(CONTENTA_REDIRECT, `${pathname}${search}`);
  }

  function unregisterServiceWorkerIfNeeded() {
    const shouldUnregister = !window.location.pathname.includes('/meet');
    console.log('--- is not page meet? ---', shouldUnregister);
    if (shouldUnregister && 'serviceWorker' in navigator) {
      console.log('--- unregistering ServiceWorker ---');
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (let registration of registrations) {
            registration.unregister().then((boolean) => {
              console.log(`Service Worker unregistered: ${boolean}`);
            });

            window.location.reload();
          }
        })
        .catch((error) => {
          console.error(`Failed to unregister service workers: ${error}`);
        });
    }
  }

  unregisterServiceWorkerIfNeeded();

  return (
    <UserProvider>
      <LanguageProvider language={localStorageLanguage}>
        <SearchProvider>
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyle />
            <AppWrapper />
          </ThemeProvider>
        </SearchProvider>
      </LanguageProvider>
    </UserProvider>
  );
}

export default App;
