import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Skeleton } from 'antd';
import MentorCard from 'pages/TravellerPages/Home/MentorCard';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as S from './styles';

const Wrapper = ({ children }) => {
  const isMobile = window.innerWidth < 1024;

  const swiperProps = {
    spaceBetween: 16,
    slidesPerView: 'auto',
    className: 'not-talked__mentors-card',
    navigation: isMobile ? false : true,
    modules: isMobile ? [Pagination] : [Navigation],
    pagination: isMobile
      ? { dynamicBullets: true, clickable: true, dynamicMainBullets: 2 }
      : undefined,
  };

  return <Swiper {...swiperProps}>{children}</Swiper>;
};

function MentorListCarousel({ title, mentors, isLoading }) {
  if (isLoading) {
    return (
      <S.NotTalkedContainer>
        <Wrapper>
          {Array.from({ length: 15 }).map(() => (
            <SwiperSlide style={{ width: 266 }}>
              <Skeleton
                style={{ width: 266, padding: '16px 0' }}
                avatar
                paragraph={{
                  rows: 6,
                }}
              />
            </SwiperSlide>
          ))}
          <SwiperSlide />
        </Wrapper>
      </S.NotTalkedContainer>
    );
  }

  if (!mentors || mentors.length === 0) {
    return null;
  }

  return (
    <S.NotTalkedContainer>
      <div className="not-talked__mentors">
        <h3>{title}</h3>
      </div>

      <Wrapper>
        {mentors?.map((mentor) => (
          <SwiperSlide>
            <MentorCard mentor={mentor} />
          </SwiperSlide>
        ))}
        <SwiperSlide />
      </Wrapper>
    </S.NotTalkedContainer>
  );
}

export default MentorListCarousel;
