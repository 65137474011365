import { MentorAboutContainer } from './styles';
import ModalReadMore from './ModalReadMore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMentor } from '../mentorContext';
import { Skeleton } from 'antd';

const MentorAbout = () => {
  const { t } = useTranslation();
  const { isLoading, mentor } = useMentor();
  const [openModal, setOpenModal] = useState(false);

  if (isLoading) {
    return (
      <MentorAboutContainer>
        <div className="mentor-about__block-content">
          <Skeleton active size="large" />
        </div>
      </MentorAboutContainer>
    );
  }

  const { consultRegionDetails, summary } = mentor;

  return (
    <MentorAboutContainer>
      {summary && (
        <div className="mentor-about__block-content">
          <p>
            {summary?.length > 200 ? `${summary.slice(0, 200)}...` : summary}
          </p>
          {summary?.length > 200 && (
            <span
              className="mentor-about__link"
              onClick={() => setOpenModal(true)}
            >
              {t('mentorProfile.readMore')}
            </span>
          )}
        </div>
      )}

      <div className="mentor-about__block-content">
        <h2 className="mentor-about__block-title">
          {t('mentorProfile.aboutMyArea')}
        </h2>
        <p>{consultRegionDetails}</p>
      </div>

      <ModalReadMore
        about={summary}
        onClose={() => setOpenModal(false)}
        open={openModal}
      />
    </MentorAboutContainer>
  );
};

export default MentorAbout;
