import { ContentaText } from 'components/Styled/ContentaText';
import { useMentor } from '../../mentorContext';
import MentorSpecialties from '../../MentorSpecialties';
import { useTranslation } from 'react-i18next';
import { Skeleton, Space } from 'antd';

const MotivateToBeExpert = () => {
  const { advisor, isLoading } = useMentor();
  const { t } = useTranslation();

  const { consultRegionDetails, summary } = advisor;
  const videoUrl = advisor?.videoUrl || null;

  if (isLoading) {
    return (
      <section style={{ display: 'flex', flexDirection: 'column' }}>
        <Skeleton.Node
          active
          style={{
            width: '100%',
            height: '300px',
          }}
        >
          <span />
        </Skeleton.Node>
        <Space className="mt-8">
          <Skeleton.Button active size="small" shape="false" block={false} />
          <Skeleton.Button active size="small" shape="false" block={false} />
          <Skeleton.Button active size="small" shape="false" block={false} />
        </Space>

        <Skeleton active />

        <Space className="mt-8 d-flex">
          <Skeleton.Button
            active
            size="small"
            shape="false"
            block={true}
            style={{
              width: 200,
            }}
          />
        </Space>

        <Skeleton active />
      </section>
    );
  }

  return (
    <section>
      {/* video */}
      <video src={videoUrl} controls></video>

      {/* especialidades */}
      <MentorSpecialties />

      {/* about me */}
      <ContentaText color="secondary">{summary}</ContentaText>

      {/* about my area */}
      <ContentaText color="primary" size="large" bold>
        {t('mentorProfile.aboutMyArea')}
      </ContentaText>

      <ContentaText color="secondary">{consultRegionDetails}</ContentaText>
    </section>
  );
};

export default MotivateToBeExpert;
