/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { DatePicker, Select } from 'antd';
import FlagIcon from 'components/Styled/Flag';
import { COUNTRIES_LIST } from 'constants/countries';
import { COUNTRIES_PHONE_CODE } from 'constants/countries-phone-code';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePersonalAddressInputs from './usePersonalAddress';

const useTravellerInputs = ({ form }) => {
  const { t } = useTranslation();
  const initialValues = form.getFieldsValue();
  const { addressInputs, zipcodeValues, addressValue } =
    usePersonalAddressInputs(initialValues);

  const [formErrors, setFormErrors] = useState({});

  const isProfile = window.location.pathname.includes('profile');

  const validateDate = (date) => {
    if (date) {
      const birthDate = new Date(date);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate()))
        age--;
      if (age < 18) {
        setFormErrors({
          ...formErrors,
          birthDate: true,
        });

        return;
      }
    }

    setFormErrors({
      ...formErrors,
      birthDate: false,
    });
  };

  const inputs = [
    {
      name: 'name',
      label: t('guides.name'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.nameRequired'),
      col: {
        xs: 24,
        sm: 12,
        lg: 12,
      },
    },
    {
      name: 'surname',
      label: t('guides.surname'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.surnameRequired'),
      col: {
        xs: 24,
        sm: 12,
        lg: 12,
      },
    },
    ...(isProfile
      ? [
          {
            name: 'socialName',
            label: t('guides.socialName'),
            className: 'is-number-input',
            isRequired: false,
            hasFeedback: false,
            col: {
              xs: 24,
              sm: 24,
              lg: 24,
            },
          },
        ]
      : []),

    {
      name: 'birthDate',
      isDate: true,
      className: 'is-number-input',
      label: t('guides.birthday'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.dayRequired'),
      placeholder: t('guides.day'),
      input: (
        <DatePicker
          placeholder={t('guides.birthday')}
          onChange={validateDate}
          format={['D/MM/YYYY', 'DDMMYYYY']}
        />
      ),
      col: {
        xs: 24,
        sm: 12,
        lg: 12,
      },
    },
    {
      name: 'nationality',
      label: t('guides.nationality'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.nationalityRequired'),
      col: {
        xs: 24,
        sm: 12,
        lg: 12,
      },
      input: (
        <Select
          aria-autocomplete="none"
          allowClear
          showSearch
          optionFilterProp="name"
          filterOption={(input, option) =>
            option.children
              .toLocaleLowerCase()
              .includes(String(input).toLocaleLowerCase())
          }
        >
          {COUNTRIES_LIST.map((currency) => (
            <Select.Option key={currency.code} value={currency.code}>
              {currency.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'gender',
      label: t('register.gender'),
      isRequired: true,
      hasFeedback: true,
      message: t('register.genderRequired'),
      col: {
        xs: 24,
        sm: isProfile ? 8 : 24,
        lg: isProfile ? 12 : 10,
      },
      input: (
        <Select
          aria-autocomplete="none"
          allowClear
          showSearch
          optionFilterProp="name"
        >
          <Select.Option value="MASCULINE">
            {t('register.masculine')}
          </Select.Option>
          <Select.Option value="FEMININE">
            {t('register.feminine')}
          </Select.Option>
          <Select.Option value="NON_BINARY">
            {t('register.nonBinary')}
          </Select.Option>
          <Select.Option value="PREFER_NOT_TO_SAY">
            {t('register.preferNotToSay')}
          </Select.Option>
        </Select>
      ),
    },
    {
      name: 'maritalStatus',
      label: t('register.civilStatus'),
      isRequired: true,
      hasFeedback: true,
      message: t('register.civilStatusRequired'),
      col: {
        xs: 24,
        sm: 24,
        lg: 24,
      },
      input: (
        <Select
          aria-autocomplete="none"
          allowClear
          showSearch
          optionFilterProp="name"
          placeholder={t('register.civilStatus')}
        >
          <Select.Option value="SINGLE">{t('register.single')}</Select.Option>
          <Select.Option value="MARRIED">{t('register.married')}</Select.Option>
          <Select.Option value="DIVORCED">
            {t('register.divorced')}
          </Select.Option>
          <Select.Option value="WIDOWER">{t('register.widower')}</Select.Option>
          <Select.Option value="SEPARATED">
            {t('register.separated')}
          </Select.Option>
          <Select.Option value="OTHERS">{t('register.others')}</Select.Option>
        </Select>
      ),
    },
  ];

  const contactInput = [
    {
      name: 'ddi',
      label: t('guides.ddi'),
      isRequired: true,
      input: (
        <Select
          aria-autocomplete="none"
          allowClear
          showSearch
          optionFilterProp="code"
          filterOption={(input, option) =>
            String(option.children)
              .toLocaleLowerCase()
              .includes(String(input).toLocaleLowerCase())
          }
        >
          {COUNTRIES_PHONE_CODE.map((phoneCode) => (
            <Select.Option key={phoneCode.iso} value={phoneCode.code}>
              <FlagIcon code={phoneCode.iso.toLocaleLowerCase()} size="lg" />{' '}
              <span className="is-number-input">{phoneCode.code}</span>
            </Select.Option>
          ))}
        </Select>
      ),
      message: t('guides.ddiRequired'),
      col: {
        xs: 8,
        sm: 5,
        lg: 4,
      },
    },
    {
      name: 'mobilePhone',
      label: t('guides.mobilePhone'),
      isRequired: true,
      hasFeedback: true,
      className: 'is-number-input',
      message: t('guides.mobilePhoneRequired'),
      col: {
        xs: 12,
        sm: 7,
        lg: 8,
      },
    },
    {
      name: 'email',
      label: t('guides.email'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.emailRequired'),
      col: {
        xs: 12,
        sm: 12,
        lg: 12,
      },
    },
  ];

  const contactsAdressInput = [...addressInputs];

  useEffect(() => {
    for (const key in zipcodeValues) {
      form?.setFieldsValue({
        [key]: zipcodeValues[key],
      });
    }
  }, [zipcodeValues]);

  return {
    inputs,
    contactsAdressInput,
    contactInput,
    formErrors,
    addressValue,
  };
};

export default useTravellerInputs;
