import * as Sentry from '@sentry/react';

const errorLogger = ({ message, error, extraInfo }) => {
  Sentry.captureMessage(message);
  Sentry.captureException(error);

  if (extraInfo) {
    Sentry.setExtra('extraInfo', extraInfo);
  }
};

export default errorLogger;
