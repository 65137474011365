import { isUserTraveller } from 'utils/isRole';
import HttpClient from './utils/HttpClient';

import * as Sentry from '@sentry/react';

class BookingService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  // Retorna a agenda disponível do advisor (publico)
  async getBookingByAdvisorId({ advisorId, params }) {
    if (!advisorId) {
      //
      Sentry.captureException(
        new Error(
          `[BOOKING][GET BOOKING BY ADVISOR ID][ERROR] - advisorId is required`
        ),
        {
          level: 'error',
          contexts: {
            formData: { advisorId, params },
          },
        }
      );
      return;
    }

    return this.httpClient.publicGet(`/booking/${advisorId}${params}`);
  }

  // Retorna a agenda marcada para a Contenta da YCBM do traveler logado (traveler)
  // ou
  // Retorna a agenda marcada para a Contenta da YCBM do advisor logado,
  // agrupado por dia e retornando os horários que o cara trabalha e cada status dos slots de horário. (advisor aprovado)
  async getBookingScheduledUser({ role, params }) {
    const queryString = new URLSearchParams(params).toString();

    if (isUserTraveller(role)) {
      return this.httpClient.get(`/booking/traveler/scheduled?${queryString}`);
    }
    return this.httpClient.get(`/booking/advisor/scheduled?${queryString}`);
  }

  async rescheduleBooking({ bookingId, data, role }) {
    if (isUserTraveller(role)) {
      return this.httpClient.put(`/booking/${bookingId}/reschedule`, data);
    }

    return this.httpClient.put(`/booking/${bookingId}/reschedule`, {});
  }

  async cancelBooking({ bookingId }) {
    return this.httpClient.delete(`/booking/${bookingId}/cancel`);
  }

  async getBookingById({ bookingId }) {
    return this.httpClient.get(`/booking/${bookingId}/scheduling`);
  }
}

export default new BookingService();
