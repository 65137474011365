export default {
  translations: {
    common: {
      avaliation: 'Avaliação',
      unexpectedError: 'Ocorreu um erro inesperado',
      couponExtraValue:
        'Voucher aplicado com sucesso! Seu voucher cobriu 100% do valor e você ainda terá um crédito de {AMOUNT_CREDIT}! Enviaremos um novo voucher com o valor restante para você via e-mail!',
      couponExaclyValue:
        'Voucher aplicado com sucesso! Seu voucher cobre 100% do custo!',
      couponMissingValue: 'Voucher aplicado com sucesso!',
      comeBackAndFinish: 'Ei, volte e termine sua reserva!',
      noRate: 'Não há avaliações ainda',
      addPhoto: 'Adicionar foto',
      address: 'Endereço',
      answer: 'Responder',
      back: 'Voltar',
      notInformed: 'Not informed',
      betaVersion: 'versão beta',
      cancel: 'Cancelar',
      canceled: 'Cancelado',
      suspended: 'Suspenso',
      close: 'Fechar',
      confirm: 'Confirmar',
      contentaPhraseLogo:
        'planejamento de viagens online, tours privados únicos',
      continue: 'Continuar',
      create: 'Criar',
      delete: 'Excluir',
      de: 'de',
      edit: 'Editar',
      find: 'Encontrar',
      finish: 'Finalizar',
      hello: 'Olá',
      hour: 'Hora',
      hours: 'Horas',
      idioms: 'Idiomas',
      inPerson: 'Presencial',
      loading: 'Carregando',
      logout: 'Sair',
      refuseds: 'Recusados',
      register: 'Registrar',
      remove: 'Remover',
      requestChange: 'Solicitar alteração',
      reschedule: 'Reagendar',
      save: 'Salvar',
      saving: 'Salvando',
      selfRegister: 'Cadastre-se',
      send: 'Enviar',
      searchPlace: 'Pesquisar local...',
      success: 'Sucesso',
      successUpdate: 'Atualizado com sucesso',
      tryAgain: 'Tentar novamente',
      underConstruction: 'Em construção',
      noAvailableSchedules: 'Nenhum horário disponível.',
      advisor: 'Advisor',
      didntShowUp: 'não compareceu',
      you: 'Você',
      traveler: 'Viajante',
      underConstructionSubTitle:
        'Em breve estaremos disponibilizando essa funcionalidade.',
      update: 'Atualizar',
      upload: 'Carregar',
      understand: 'Entendi',
      virtual: 'Virtual',
      traveller: 'Viajante',
      advisorPhrase: 'Frase do Advisor',
      select: 'Selecione uma opção',
      requiredField: 'Campo obrigatório',
      showMore: 'Mostrar mais',
      showLess: 'Mostrar menos',
      languages: {
        'PT-BR': 'Português',
        'pt-BR': 'Português',
        'en-US': 'Inglês',
        es: 'Espanhol',
      },
      error: {
        generic: 'Ocorreu um erro',
        errorFetching: 'Erro ao buscar dados',
        errorFetchingFutureSchedules:
          'Não foi possível carregar os horários futuros',
        errorFetchingPastSchedules:
          'Não foi possível carregar os horários passados',
        errorFetchingAdvisors: 'Não foi possível carregar os Advisors',
        createSchedule: 'Não foi possível criar o agendamento',
        newsletter: 'Não foi possível se inscrever na newsletter',
        under18: 'Você precisa ter mais de 18 anos',
        rescheduleMeeting: 'Não foi possível reagendar a reunião',
        submitResume: 'Não foi possível enviar o resumo',
        joinMeetingFailed: 'Não foi possível entrar na reunião',
        failedResendEmail: 'Não foi possível reenviar o e-mail',
        cookiesDisabled: 'Os cookies estão desabilitados',
        serverError: 'Erro no servidor',

        enableCookies: {
          title: 'Como Habilitar Cookies',
          chrome: {
            step1: 'No Google Chrome.',
            step2:
              'Clique nos três pontos verticais no canto superior direito e selecione "Configurações".',
            step3: 'No menu à esquerda, clique em "Privacidade e segurança".',
            step4: 'Clique em "Cookies e outros dados do site".',
            step5:
              'Certifique-se de que "Permitir todos os cookies" está selecionado ou que "Bloquear cookies de terceiros" não está ativado.',
            step6: 'Feche a aba de configurações.',
          },
          firefox: {
            step1: 'No Mozilla Firefox.',
            step2:
              'Clique nas três linhas horizontais no canto superior direito e selecione "Configurações".',
            step3: 'No menu à esquerda, clique em "Privacidade e Segurança".',
            step4:
              'Na seção "Cookies e Dados de Sites", verifique se "Aceitar cookies e dados de sites" está selecionado.',
            step5: 'Feche a aba de configurações.',
          },
          edge: {
            step1: 'No Microsoft Edge.',
            step2:
              'Clique nos três pontos horizontais no canto superior direito e selecione "Configurações".',
            step3:
              'No menu à esquerda, clique em "Cookies e permissões de site".',
            step4: 'Clique em "Gerenciar e excluir cookies e dados do site".',
            step5:
              'Certifique-se de que "Permitir que sites salvem e leiam dados de cookies" está ativado.',
            step6: 'Feche a aba de configurações.',
          },
          safari: {
            step1: 'No Safari.',
            step2:
              'Clique em "Safari" na barra de menus e selecione "Preferências".',
            step3: 'Clique na aba "Privacidade".',
            step4:
              'Certifique-se de que "Impedir rastreamento entre sites" está desmarcado.',
            step5: 'Feche a janela de preferências.',
          },
          finalNote:
            'Após seguir estes passos, por favor, recarregue a página. Se você encontrar algum problema, sinta-se à vontade para verificar a documentação de suporte do seu navegador.',
        },
      },
      successMessages: {
        createSchedule: 'Agendamento criado com sucesso',
        submitResume: 'Resumo enviado com sucesso',
      },
    },
    giftCardModal: {
      title: 'Cartão Presente',
      buttonPayment: 'Detalhes da compra',
      buttonSchedule: 'Ir para detalhes da compra',
      buttonChooseOption: 'Selecionar Travel Expert',
      name: 'Nome do presenteado',
      email: 'Email do presenteado',
      message: 'Mandar uma mensagem',
      giftCardData: 'Dados do presenteado',
      scheduleData: 'Agendamento',
      desiredValue: 'Valor desejado',
      sendDate: 'Data de envio do cartão presente',
      wantChooseAdvisor: 'Quero escolher o Travel Expert',
      wantUserChoose: 'Quero que ${USER} escolha',
      giftedName: 'Presenteado',
    },
    login: {
      user: 'Usuário',
      password: 'Senha',
      login: 'Entrar',
      loginAsAdvisor: 'Entrar como <br />Travel Expert',
      loginAsTraveller: 'Entrar como <br />Viajante',
      usernameRequired: 'O e-mail é obrigatório',
      passwordRequired: 'A senha é obrigatória',
      forgotPassword: 'Esqueceu a senha?',
      createAccount: 'Criar uma conta',
      loginGoogle: 'Entrar com Google',
      slogan: '_o mundo é nosso.',
      sloganTraveler: 'travel without limits - viaje sem limites.',
      or: 'Ou',
      passwordNumber: 'A senha deve conter pelo menos um número',
      passwordSpecialChar:
        'A senha deve conter pelo menos um caractere especial',
      confirmEmail: 'Por favor, confirme seu e-mail!',
      passwordMinLength: 'A senha deve ter no mínimo 8 caracteres',
      passwordUppercase: 'A senha deve conter pelo menos uma letra maiúscula',
      confirmPassword: 'Por favor, confirme sua senha!',
      // Passwords do not match!
      passwordsDoNotMatch: 'As senhas não coincidem!',
      slidesTraveler: {
        slide1: {
          title: 'Gastando 60 ou mais horas planejando viagens?',
          description:
            'Planejar sua viagem com os nossos travel experts, te ajuda a planejar mais rápido e melhor.',
        },
        slide2: {
          title: 'Visitando 50 ou mais sites de viagens?',
          description:
            'Faça uma chamada de vídeo com um travel expert e você terá em um único lugar acesso a dicas personalizadas de forma rápida e divertida.',
        },
        slide3: {
          title: 'Conhecendo e passeando por lugares turísticos comuns?',
          description:
            'Nossos especialistas criam um tour privado único, feito sob medida para você.',
        },
      },
      slidesAdvisor: {
        slide1: {
          title: 'Seja pago por reservas online e tours privados',
          description:
            'Viajantes reservam videochamada, ONLINE, para planejamento de viagens',
        },
        slide2: {
          title: 'Trabalhe remotamente',
          description:
            'Você é pago por hora para ajudar com dicas e sugestões seguras e confiáveis, que somente um local pode dar.',
        },
        slide3: {
          title: 'Repita.',
          description:
            'Os viajantes podem reservar você para várias sessões de planejamento para a mesma viagem.',
        },
        slide4: {
          title: 'Tours presenciais',
          description:
            'Os viajantes também podem te contratar para passeios privados guiados locais.',
          content: {
            title: 'Você é bem-vindo para se juntar ao nosso time se você for:',
            bulletPoints: {
              1: 'Um guia de turismo licenciado e experiente.',
              2: 'Um especialista local que é criativo, inovador e habilidoso em personalizar roteiros e passeios.',
              3: 'Apaixonado por conectar-se com as pessoas e valorizar a diversidade.',
              4: 'Capaz de manter uma conexão de internet confiável e ter um espaço tranquilo e estável para chamadas com clientes.',
              5: 'Comprometido em oferecer experiências de viagem inesquecíveis para os nossos viajantes.',
            },
          },
        },
      },
    },
    notVerified: {
      title: 'Verifique seu e-mail!',
      traveller: {
        description: `<p>Enviamos um e-mail para <strong>{EMAIL}</strong>. Clique no link para verificar sua conta.</p>`,
      },
      advisor: {
        description: `<p>Enviamos um e-mail para <strong>{EMAIL}</strong>. Clique no link para verificar sua conta.</p>
        <p>A verificação é necessária para que você possa usufruir de todos os recursos da plataforma e aparecer como um Travel Expert para os viajantes.</p>`,
      },
      resend: 'Reenviar e-mail',
      resendSuccess: 'E-mail reenviado com sucesso!',
      resendError: 'Não foi possível reenviar o e-mail.',
      alreadyVerified: 'Realizei a verificação',
    },

    home: {
      title: 'Quer receber as melhores dicas de viagens?',
      subtitle:
        'Encontre os guias turísticos especialistas no seu estilo de viagem.',
      firstInput: {
        label: 'Onde?',
        placeholder: 'Quais sãos os seus planos?',
      },
      secondInput: {
        label: 'Quais experiências você procura?',
        placeholder: 'Quais são os seus planos?',
      },
      giftText: 'Presenteie alguém especial com uma experiência incrível',
      giftButton: 'Cartão Presente',
      notTalked: 'Você ainda não conversou com nossos guias?',
      letsStart: 'Vamos começar criando seu primeiro agendamento.',
      newSchedulle: 'Novo agendamento',
      banner: {
        contenta: '_ A Contenta 360º',
        paragraph:
          '<p>Conecte-se com nossos Travel Experts que moram no destino que deseja visitar. Nossos Travel Experts são guias turísticos locais e especialistas profissionais.</p><p>Eles estão prontos para auxiliá-lo em todas as etapas do planejamento de sua viagem; de reuniões virtuais de pré-planejamento a visitas presenciais.</p>',
        buttonKnow: 'Saiba mais',
        title:
          'Você é <strong>Guia de Turismo</strong> e gostaria de ser um  <strong>Travel Expert</strong>?',
        beAdvisor: 'Cadastre-se como Travel Expert',
      },
      countryNotInformed: 'País não informado',
    },
    CSV: {
      advisorName: 'Nome do guia',
      advisorSurname: 'Sobrenome do guia',
      advisorEmail: 'Email do guia',
      advisorNationality: 'Nacionalidade do guia',
      advisorSummary: 'Resumo do guia',
      advisorBirthDate: 'Data de nascimento do guia',
      advisorMobilePhone: 'Celular do guia',
      advisorPhone: 'Telefone do guia',
      languageName: 'Nome do idioma',
      customLanguageLanguage: 'Outro idioma',
      consultingRegionName: 'Nome da região',
      consultingRegionCountry: 'País da região',
      consultingRegionState: 'Estado da região',
      consultingRegionCity: 'Cidade da região',
      consultingRegionDetails: 'Detalhes da região',
      companyName: 'Nome da empresa',
      companyDocument: 'Documento da empresa',
      companyPosition: 'Cargo da empresa',
      classAssociationName: 'Nome da associação',
      classAssociationRegisterNumber: 'Número do registro da associação',
      classAssociationAssociatedSince: 'Associado desde',
      addressCountry: 'País do endereço',
      addressState: 'Estado do endereço',
      addressCity: 'Cidade do endereço',
      addressStreet: 'Rua do endereço',
      addressZipcode: 'CEP do endereço',
      addressComplement: 'Complemento do endereço',
      specialties: 'Especialidades',
      customSpecialty: 'Outras especialidades',
      createdAt: 'Criado em',
      updatedAt: 'Atualizado em',
      currency: 'Moeda',
      valueHour: 'Valor/hora',
    },
    guides: {
      bankNameRequired: 'Nome do banco é obrigatório',
      swiftCodeRequired: 'Código SWIFT é obrigatório',
      ibanRequired: 'Código IBAN é obrigatório',
      bankNumberRequired: 'Número do banco é obrigatório',
      agencyNumberRequired: 'Número da agência é obrigatório',
      accountNumberRequired: 'Número da conta é obrigatório',
      socialName: 'Nome social',
      checkData: 'Verifique os dados antes de continuar',
      register: 'Cadastro de Travel Expert',
      alreadyAccount: 'Já tem uma conta?',
      enterHere: 'Entre aqui',
      step: 'Passo',
      step1: 'Informações pessoais',
      step2: 'Informações profissionais',
      step3: 'Sobre você',
      step4: 'Suas especialidades',
      step5: 'Usuário e senha',
      clickHere: 'clicando aqui',
      name: 'Nome',
      afterRegister:
        'Após agendar sua entrevista com nossa equipe, clique abaixo para acessar seu perfil.',
      registerSuccess1: 'obrigado por se cadastrar na Contenta360!',
      registerSuccessParagraph: `<p>Valorizamos a qualidade e segurança em cada experiência que oferecemos. Por isso, a próxima etapa é uma entrevista com nossa equipe, uma oportunidade para nos conhecermos melhor e entendermos o seu trabalho.</p>
      <p><strong>ATENÇÃO</strong>: Sua conta de Travel Expert somente estará disponível após essa conversa com a equipe da Contenta360.</p>
      <p>Clique no botão abaixo para agendar o melhor horário para você.</p>`,
      nameRequired: 'O nome é obrigatório',
      surname: 'Sobrenome',
      surnameRequired: 'O sobrenome é obrigatório',
      birthday: 'Nascimento',
      day: 'Dia',
      dayRequired: 'Data é obrigatório',
      month: 'Mês',
      monthRequired: 'Mês é obrigatório',
      year: 'Ano',
      ddi: 'DDI',
      yearRequired: 'Ano é obrigatório',
      ddiRequired: 'O DDI é obrigatório',
      mobilePhone: 'Celular',
      mobilePhoneRequired: 'O celular é obrigatório',
      nationality: 'Nacionalidade',
      nationalityRequired: 'País é obrigatório',
      residenceCountry: 'País de residência',
      residenceCountryRequired: 'País de residência é obrigatório',
      fullAddress: 'Endereço completo',
      zipcode: 'CEP',
      zipcodeRequired: 'CEP é obrigatório',
      state: 'Estado',
      stateRequired: 'Estado é obrigatório',
      city: 'Cidade',
      cityRequired: 'Cidade é obrigatória',
      street: 'Endereço',
      streetRequired: 'Endereço é obrigatório',
      complement: 'Complemento',
      neighborhood: 'Bairro',
      neighborhoodRequired: 'Bairro é obrigatório',
      nameCompany: 'Nome da empresa',
      nameCompanyRequired: 'Nome da empresa é obrigatório',
      number: 'Número',
      specialties: 'Quais são as suas especialidades?',
      chooseUpToFiveOptions: 'Escolha até 5 opções',
      chooseUpToFiveOptionsLimit: 'Não mais do que 5 opções',
      chooseUpToFiveOptionsRequired: 'Por favor, escolha até 5 opções',
      customSpecialty: 'Outras',
      tellAboutYourHistory:
        'Conte um pouco da sua história e demonstre aqui sua paixão pelo que faz.',
      tellAboutYourHistoryDesc:
        'Em um dos idiomas que você domina, relate um pouco sobre você. Ex: seu tempo de formação e experiência profissional, suas especialidades na região onde trabalha, número de clientes atendidos.',
      tellAboutYourHistoryRequired: 'Por favor, preencha este campo',
      tellAboutYourHistoryCharLimit: 'Não mais do que 2500 caracteres',
      association: 'Órgão Emissor do Registro',
      associationRequired: 'Orgão Emissor do Registro é obrigatório',
      registerNumber: 'Número de matrícula',
      registerNumberRequired: 'Número de matrícula é obrigatório',
      numberRequired: 'Número é obrigatório',
      associatedDay: 'Associado desde',
      associatedDayRequired: 'Data é obrigatório',
      valueHour: 'Valor/hora',
      investmentValue: 'Valor do investimento',
      valueHourRequired: 'Valor/hora é obrigatório',
      videoCallPrice: 'Chamada de vídeo',
      presentialTour: 'Tour presencial',
      internationalPurchase: 'Compra internacional',
      packageValue: 'Valor por pacote',
      currency: 'Moeda',
      currencyPlaceholder: 'Selecione uma moeda',
      currencyRequired: 'Moeda é obrigatória',
      companyName: 'Nome da empresa',
      companyNameRequired: 'Nome da empresa é obrigatório',
      companyDocument: 'CNPJ',
      companyDocumentRequired: 'CNPJ é obrigatório',
      companyRole: 'Cargo',
      companyRoleRequired: 'Cargo é obrigatório',
      registerTuristGuide:
        'Registro de Guia de Turismo e/ou Registro na Associação Profissional',
      isRequiredLicense:
        'É obrigatório possuir licença de guia de turismo no país onde você trabalha?',
      chooseAOption: 'Escolha uma opção',
      chooseAOptionRequired: 'Campo é obrigatório',
      yes: 'Sim',
      no: 'Não',
      depends: 'Depende',
      monthJanuary: 'Janeiro',
      monthFebruary: 'Fevereiro',
      monthMarch: 'Março',
      monthApril: 'Abril',
      monthMay: 'Maio',
      monthJune: 'Junho',
      monthJuly: 'Julho',
      monthAugust: 'Agosto',
      monthSeptember: 'Setembro',
      monthOctober: 'Outubro',
      monthNovember: 'November',
      monthDecember: 'December',
      country: 'País',
      countryRequired: 'Pais é obrigatório',
      consultancyDetails:
        'Detalhe um pouco mais sobre sua região de atuação e área de conhecimento',
      aboutDestination: 'Sobre o destino turístico onde você atua.',
      consultancyRegion: 'Região onde você guia ou trabalha',
      languages: 'Idiomas',
      languageRequired: 'Idioma é obrigatório',
      otherLanguages: 'Outras',
      photo: 'Foto',
      photoIsRequired: 'Foto é obrigatória',
      email: 'E-mail',
      mustBeAdult: 'Você deve ser maior de idade',
      invalidDate: 'Data inválida',
      registerNumberDetails: 'Favor detalhar',
      registerNumberDetailsRequired: 'Campo é obrigatório',
      profilePhrase:
        'Conte-nos, em uma frase, por que você é apaixonado pela sua profissão. Essa será a frase do seu perfil na homepage da Contenta360.',
      profilePhraseRequired: 'Campo é obrigatório',
      zipcodeError: 'Não foi possível encontrar o CEP',
      empty: 'Nenhum advisor foi encontrado com os filtros selecionados',
    },
    languages: {
      german: 'Alemão',
      english: 'Inglês',
      korean: 'Coreano',
      spanish: 'Espanhol',
      french: 'Francês',
      italian: 'Italiano',
      italiano: 'Italiano',
      japanese: 'Japonês',
      portuguese: 'Português (Portugal)',
      portugueseBrazil: 'Português (Brasil)',
      code: {
        DE: 'Alemão',
        IT: 'Italiano',
        KO: 'Coreano',
        JA: 'Japonês',
        ES: 'Espanhol',
        'PT-BR': 'Português (Brasil)',
        FR: 'Francês',
        'PT-PT': 'Português (Portugal)',
        EN: 'Inglês',
        en_US: 'Inglês',
        'en-US': 'Inglês',
        en: 'Inglês',
      },
    },
    specialties: {
      notFound: 'Especialidades não encontradas',
      adrenaline: 'Adrenalina',
      beach: 'Praia',
      mountain: 'Montanha',
      urban: 'Urbano',
      cruise: 'Cruzeiro',
      resort: 'Resort',
      gastronomy: 'Gastronomia',
      wine: 'Vinho',
      adventure: 'Aventura',
      ecotourism: 'Ecoturismo',
      shopping: 'Compras',
      culture: 'Cultura',
      sports: 'Esportes',
      'Natureza & Aventura': 'Natureza & Aventura',
      'Urbano - Lifestyle': 'Urbano - Lifestyle',
      'Vinho, Azeite & Gastronomia': 'Vinho, Azeite & Gastronomia',
      'Aventuras & Esportes': 'Aventuras & Esportes',
      'História, Arte & Cultura': 'História, Arte & Cultura',
      'Espiritualidade e Religião': 'Espiritualidade e Religião',
      'Turismo Acessível & Necessidades Especiais':
        'Turismo Acessível & Necessidades Especiais',
      'Viajar em Família ': 'Viajar em Família ',
      'Saúde & Bem Estar': 'Saúde & Bem Estar',
      'Viagem Econômica - Super Dicas': 'Viagem Econômica - Super Dicas',
      'Turismo LGBTQIA+': 'Turismo LGBTQIA+',
      'Viagens de Luxo': 'Viagens de Luxo',
      Afroturismo: 'Afroturismo',
      'Comida e Gastronomia': 'Comida e Gastronomia',
      'Arquitetura - Castelos': 'Arquitetura - Castelos',
      'Arquitetura - Urbana': 'Arquitetura - Urbana',
      'Arte e Cultura': 'Arte e Cultura',
      'Artes - Galeria ': 'Artes - Galeria ',
      'Artes - Museu louvre': 'Artes - Museu louvre',
      'Artes - Museu Vaticano': 'Artes - Museu Vaticano',
      'Artes - Museus': 'Artes - Museus',
      'Eventos culturais - Festivais': 'Eventos culturais - Festivais',
      'História ': 'História ',
      'História - Monumentos históricos': 'História - Monumentos históricos',
      'História - Campos de batalha': 'História - Campos de batalha',
      'Natureza - Astroturismo': 'Natureza - Astroturismo',
      'Natureza - Atrações turísticas': 'Natureza - Atrações turísticas',
      'Natureza - Aurora Boreal': 'Natureza - Aurora Boreal',
      'Natureza - Montanhismo': 'Natureza - Montanhismo',
      'Natureza - Navegações fluviais': 'Natureza - Navegações fluviais',
      'Rural - Vida Local': 'Rural - Vida Local',
      'Aventuras e esportes - Bike': 'Aventuras e esportes - Bike',
      'Aventuras e esportes - Downhill': 'Aventuras e esportes - Downhill',
      'Aventuras e esportes - Hiking': 'Aventuras e esportes - Hiking',
      'Aventuras e esportes  Mergulho': 'Aventuras e esportes  Mergulho',
      'Aventuras e esportes - Mountain bike':
        'Aventuras e esportes - Mountain bike',
      'Aventuras e esportes - Selvas': 'Aventuras e esportes - Selvas',
      'Aventuras e esportes - Ski': 'Aventuras e esportes - Ski',
      'Aventuras e esportes - Trekking': 'Aventuras e esportes - Trekking',
      'Aventuras e esportes - Trilhas': 'Aventuras e esportes - Trilhas',
      'Esportes - Corridas e Maratonas': 'Esportes - Corridas e Maratonas',
      'Comida - Comida de rua': 'Comida - Comida de rua',
      'Comida - Escola de gastronomia': 'Comida - Escola de gastronomia',
      'Comida - Gastronomia': 'Comida - Gastronomia',
      'Cruzeiros - Navegações Fluviais': 'Cruzeiros - Navegações Fluviais',
      'Cruzeiros - Navegações marítimas ': 'Cruzeiros - Navegações marítimas ',
      'Enogastronomia ': 'Enogastronomia ',
      'Vinho - Enoturismo - Vinícolas': 'Vinho - Enoturismo - Vinícolas',
      'Vinho - Experiência Gastronômica': 'Vinho - Experiência Gastronômica',
      Espiritualidade: 'Espiritualidade',
      'Turismo religioso': 'Turismo religioso',
      'Nômades digitais ': 'Nômades digitais ',
      'Saúde e bem estar - Spa no campo': 'Saúde e bem estar - Spa no campo',
      'Saúde e bem estar - Spa urbano': 'Saúde e bem estar - Spa urbano',
      'Saúde e bem estar - Termas': 'Saúde e bem estar - Termas',
      'Urbano - Dicas de compras': 'Urbano - Dicas de compras',
      'Urbano - Vida local': 'Urbano - Vida local',
      'Urbano - Vida quotidiana': 'Urbano - Vida quotidiana',
      'Viajar com crianças': 'Viajar com crianças',
      'Viajar em família ': 'Viajar em família ',
      'Viagens de luxo ': 'Viagens de luxo ',
      'Turismo acessível ': 'Turismo acessível ',
      Astroturismo: 'Astroturismo',
      'Esportes - Golfe': 'Esportes - Golfe',
      'Natureza - Praias': 'Natureza - Praias',
      'Natureza - Trilhas e Caminhadas': 'Natureza - Trilhas e Caminhadas',
    },
    months: {
      January: 'Janeiro',
      February: 'Fevereiro',
      March: 'Março',
      April: 'Abril',
      May: 'Maio',
      June: 'Junho',
      July: 'Julho',
      August: 'Agosto',
      September: 'Setembro',
      October: 'Outubro',
      November: 'Novembro',
      December: 'Dezembro',
      '01': 'Janeiro',
      '02': 'Fevereiro',
      '03': 'Março',
      '04': 'Abril',
      '05': 'Maio',
      '06': 'Junho',
      '07': 'Julho',
      '08': 'Agosto',
      '09': 'Setembro',
      10: 'Outubro',
      11: 'Novembro',
      12: 'Dezembro',
    },
    errors: {
      errorSizeUpload: 'A imagem deve ser menor que 2MB.',
      errorTypeUpload: 'A imagem deve ser do tipo jpeg, png ou jpg.',
      errorUpload: 'Não foi possível carregar a imagem.',
      birthDate: 'Data de nascimento inválida.',
    },

    breadcrumb: {
      calls: 'Chamadas',
      history: 'Histórico',
      home: 'Início',
      advisors: 'Advisors',
      scheduledTours: 'Tour agendados',
      pendingBriefings: 'Informações do Viajante pendentes',
      formBriefing: 'Formulário de Informações do Viajante',
    },

    footer: {
      launchedRecently:
        'Acabamos de lançar nossa plataforma, e nossa equipe de desenvolvimento está monitorando de perto quaisquer instabilidades do sistema ou interrupções de serviço, agindo rapidamente para garantir que sua experiência seja a melhor possível.',
      affiliates: 'Afiliados',
      privacy: 'Privacidade',
      terms: 'Termos',
      legal: 'Legal',
      faq: 'FAQ',
      termsConditions: 'Termos e Condições',
      privacyPolicy: 'Política de Privacidade',
      aboutUs: 'Sobre nós',
      newsletter: 'Assine nossa Newsletter:',
      subscribeSuccess: 'Obrigado por se inscrever!',
      subscribe: 'Inscrever-se',
      subscribePlaceholder: 'Informe seu e-mail',
    },
    weekdays: {
      0: 'Domingo',
      1: 'Segunda',
      2: 'Terça',
      3: 'Quarta',
      4: 'Quinta',
      5: 'Sexta',
      6: 'Sábado',
    },
    sidebar: {
      schedule: 'Agendamentos',
      home: 'Home',
      history: 'Histórico',
      findGuide: 'Procure um guia',
      account: 'Conta',
      notifications: 'Notificações',
      messages: 'Mensagens',
      settings: 'Configurações',
      profile: 'Perfil',
      admin: {
        home: 'Admin inicial',
        prices: 'Tabela de tarifas',
      },
    },

    terms: {
      title: 'Declaro que li e aceito os ',
      terms: 'Termos e Condições Gerais',
    },

    mentorList: {
      title: 'Lista de advisors',
      filterByStatus: 'Filtrar por status',
      searchPlaceholder: 'Pesquisar por nome',
      view: 'Mostrar aprovados',
      download: 'Exportar CSV',
      suspend: 'Suspender selecionados',
      showing: 'Mostrando',
      mentors: 'advisors',

      table: {
        name: 'Nome',
        country: 'País',
        subscriptionDate: 'Data de inscrição',
        status: 'Status',
      },
    },

    agenda: {
      available: 'Horários Disponíveis',
      online: 'Chamada por Vídeo',
      inPerson: 'Presencial - Tour Guiado',
      noEvents: 'Nenhum evento agendado nos próximos 3 dias.',
      title: 'Agenda',
      nextEvents: 'Próximos atendimentos',

      travellerCancelModal: {
        title: 'Cancelar atendimento',
        paragraph1: 'Tem certeza que você deseja cancelar este atendimento?',
        paragraph2:
          'Ao cancelar o atendimento, você perderá as informações preenchidas nas Informações do Viajante.',
        paragraph3:
          'Tem certeza que deseja cancelar esse atendimento? Faltam menos de 48 horas para a realização da reunião.',
        paragraph4:
          'Nesse caso, como informado no momento de contratação do serviço, é aplicada uma multa de 100% do valor original, ou seja, nenhum valor será estornado.',
        paragraph5: 'Deseja cancelar o atendimento?',
        paragraph6:
          'Tem certeza que deseja cancelar esse atendimento? Faltam menos de 72 horas para a realização da tour presencial.',
        paragraph7:
          'Nesse caso, como informado no momento de contratação do serviço, é aplicada uma multa de 100% do valor original, ou seja, nenhum valor será estornado.',
        paragraph8: 'Deseja cancelar o atendimento?',
      },

      cancelModal: {
        title: 'Cancelar atendimento',
        paragraph1: 'Tem certeza que você deseja cancelar este atendimento?',
        paragraph2:
          'O alto índice de cancelamentos <strong>impacta negativamente a sua avaliação como Travel Expert na Contenta360</strong>, e consequentemente a sua posição de recomendação no ranking de busca da plataforma.',
        paragraph3:
          'Recomendamos que você procure organizar a sua agenda, bloqueando os horários que você não poderá atender para não precisar realizar cancelamentos, caso algum cliente venha a escolher um horário inconveniente.',
        button: 'Cancelar atendimento',
        success: 'Atendimento cancelado com sucesso!',
        error: 'Não foi possível cancelar o atendimento.',
      },

      rescheduleModal: {
        title: 'Reagendar atendimento',
        paragraph1: 'Tem certeza que você deseja reagendar este atendimento?',
        paragraph2: 'Faltam menos de 48 horas para a realização da reunião.',
        paragraph3:
          'Caso você deseje reagendar, você pagará uma multa de 100% do valor original, que seriam ${EXTRA_COST_VALUE}, conforme informado no momento da contratação do serviço.',
        button: 'Realizar pagamento da multa',
        rescheduleButton: 'Confirmar reagendamento',

        advisorText: `<p>Reagendamentos não podem ser realizados pelo Travel Expert nesse momento.</p>

        <p>Ao confirmar, <strong>o sistema solicitará ao viajante</strong> que selecione um novo dia e horário da sua agenda.</p>

        <p>Por favor, verifique os horários disponíveis e bloqueie sua agenda de acordo com sua disponibilidade para que não seja necessário solicitar o reagendamento novamente.</p>

        <p>Mesmo que ofereçamos outros horários para o viajante, pode ser que o horário marcado inicialmente seja o único horário que ele tinha disponível. Nesse caso, também permitiremos que o cliente escolha cancelar gratuitamente a reunião e procure por outro Travel Expert.</p>

        <p>Deseja prosseguir com a solicitação de reagendamento?</p>

        <p>Lembre-se: O alto índice de reagendamentos <strong>impacta negativamente a sua avaliação como Travel Expert</strong>, e consequentemente, na sua posição de recomendação no ranking de busca da plataforma.</p>`,
        cancelSolicitation: 'Cancelar solicitação',
        agree: 'Sim, pedir reagendamento',
        error: 'Não foi possível reagendar o atendimento.',
        success: 'Solicitação de reagendamento enviada com sucesso!',
      },

      travellerAskedReschedule: {
        title: 'Reagendamento solicitado pelo Travel Expert',
        text: `<p>Houve um imprevisto, e o seu Travel Expert está avisando antecipadamente que não poderá comparecer à reunião na data originalmente agendada.</p>
        <div class="reschedule__hour">
          <p>Data e horário original:</p>
          <span><strong>{DATA}</strong></span>
        </div>
        <p>Nesse caso, você pode agendar uma nova data ou horário com o mesmo Travel Expert ou pedir o cancelamento gratuito dessa reunião para buscar outro guia.</p>`,
        cancelButton: 'Cancelar reunião',
        confirmButton: 'Reagendar',
        // Go to my calendar
        seeMyOptions: 'Ir para o meu calendário',
      },

      scheduleModal: {
        canceledByTraveler: 'Cancelada pelo Traveler',
        canceledByAdvisor: 'Cancelada pelo advisor',
        canceledByAdministrator: 'Cancelada pelo administrador',
        canceled: 'Cancelada',
      },
    },

    withoutYCBM: {
      title: 'Cadastre-se no nosso parceiro para gerenciar sua agenda.',
      content: {
        title: 'Conheça nosso parceiro',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer placerat viverra risus ac finibus. Donec nec metus massa. Vestibulum faucibus tellus arcu, sit amet imperdiet velit ultricies sit amet. Nam mattis enim non purus laoreet, eu finibus lacus tincidunt. Integer vel risus rhoncus, faucibus arcu id, luctus nulla.',
        boldDescription:
          'Cadastre-se utilizando o mesmo e-mail que se cadastrou na  Contenta360',
      },
      headerMessage: {
        button: 'Editar agenda',
        title: 'Configure sua agenda no nosso parceiro.',
      },
    },

    buttons: {
      accept: 'Aceitar',
      enterCall: 'Entrar na chamada',
      briefing: 'Informações do Viajante',
      seeBriefing: 'Ver Informações do Viajante',
      seeResume: 'Preencher resumo da reunião',
      reprove: 'Reprovar',
      suspend: 'Suspender',
      approve: 'Aprovar',
      bookNow: 'Reservar',
      schedule: 'Agendar',
      scheduleNow: 'Reserve agora',
      seeMore: 'Ver mais',
      advisor: 'Advisor',
      beAdvisor: 'Cadastre-se como Travel Expert',
      loginAdvisor: 'Entrar como Travel Expert',
      enter: 'Entrar',
      evaluate: 'Avalie',
      the: 'o',
      cancel: 'Cancelar',
      resume: 'Resumo',
      finishBeAdvisor: 'Finalizar cadastro Advisor',
      finishBeTraveler: 'Finalizar cadastro de viajante',
      willBeEnabled:
        'Você poderá entrar apenas 1 hora antes do início da reunião',
      outOfRange:
        'Você só terá acesso à reunião 1 hora antes e não será possível entrar após o término da reunião',
      makeLogin: 'Fazer login',
    },

    statusCode: {
      approved: 'Aprovado',
      pending: 'Pendente',
      disapproved: 'Reprovado',
      suspended: 'Suspenso',
      paid: 'Pago',
    },

    unloggedModal: {
      text: `Olá! <br /><br /> Antes de seguir com a reserva é preciso que você faça o seu login ou crie a sua conta na Contenta360. <br /><br /> Seja bem-vindo a um novo jeito de planejar viagens.`,
    },

    notTravellerModal: {
      text: `Olá! <br /><br /> Percebemos que você não está logado como viajante. <br /><br /> Para continuar com a sua reserva, por favor faça o login ou crie a sua conta na Contenta360. <br /><br /> Seja bem-vindo a um novo jeito de planejar viagens!`,
    },

    mentorDetails: {
      headers: {
        title: 'Detalhes do advsisor',
        contactInfo: 'Informações de contato',
        guideInfo: 'Registro profissional',
        companyInfo: 'Representa empresa no setor de turismo',
        destinyInfo: 'Sobre o destino em que atua',
        specialtiesInfo: 'Especialidades',
      },

      contactFields: {
        status: 'Status',
        birthday: 'Data de nascimento',
        countryBirth: 'País de nascimento',
        creationAccountDate: 'Data de criação da conta',
        mobilePhone: 'Celular',
        email: 'E-mail',
        countryResidence: 'País de residência',
        stateResidence: 'Estado',
        cityResidence: 'Cidade',
        address: 'Endereço',
        zipCode: 'CEP',
        guideStory: 'História do guia',
      },

      guideFields: {
        emitter: 'Órgão emissor',
        registration: 'Número de registro',
        associatedSince: 'Associado desde',
        valueHour: 'Valor/hora',
        currency: 'Moeda',
        city: 'Cidade',
      },

      companyFields: {
        name: 'Nome da empresa',
        cnpj: 'CNPJ',
        role: 'Cargo',
      },

      destinyFields: {
        country: 'País',
        state: 'Estado',
        city: 'Cidade',
        regionDetails: 'Detalhes da região',
        languages: 'Idiomas',
      },

      specialtiesFields: {
        options: 'Opções',
        others: 'Outras',
      },
    },

    tables: {
      tooltipTitle: 'Clique para ordenar',
    },

    filters: {
      title: 'Filtros ativos',
      name: 'Nome',
      status: 'Status',
    },

    mentorProfile: {
      expertPage: 'Veja mais detalhes',
      pricing: 'Preços',
      meetingType: {
        online: 'Online',
        presential: 'Tour presencial',
      },
      time: {
        package1h: 'Valor por hora',
        package4h: 'Valor por até 4 horas',
        package6h: 'Valor por até 6 horas',
        package8h: 'Valor por até 8 horas',
        package10h: 'Valor por até 10 horas',
      },
      bookTime: 'Reservar um horário',
      bookTimeTitle: 'Agendar horário',
      readMore: 'Ler mais',
      aboutMyArea: 'Sobre a minha área',
      priceHour: 'Preço por hora / Online meeting',
      generalInfo: 'Informações gerais',
      contact: 'Contato',
      profile: 'Perfil',
      personalData: 'Dados pessoais',
      professional: 'Profissional',
      destiny: 'Destino de atuação',
      idioms: 'Idiomas',
      video: 'Vídeo',
      license: 'License',
      addedVideo:
        'Video adicionado previamente (para substituir, bastar enviar um novo)',
      addedLicence:
        'Licença adicionada anteriormente (para substituir, basta enviar uma nova)',
      membership: 'Assinatura',
      specialties: 'Especialidades',
      financial: 'Financeiro',
      residence: 'Residência',
      profilePhrase: 'Frase de perfil',
      addNewCertificate: 'Adicionar nova certificação',
      licensedGuide: 'Licenciado',
      noPhrase: 'Este advisor ainda não possui uma frase de perfil',
      photoMessage:
        'A imagem deve ter formato <b>.jpeg</b> ou <b>.png</b> e peso máximo de <b>2MB</b>.',
      uploadingPhoto: 'Enviando foto...',
      videoMessage:
        'O vídeo deve ter formato <b>.mp4</b> e peso máximo de <b>200MB</b>.',
      guideLicense: 'Faça o upload da sua licença de guia de turismo válida.',
      guideLicenseLabelAdmin:
        'Licença de Guia de Turismo - Licença ou Registro Profissional',
      uploadLicense: 'Carregar sua Licença de Guia de Turismo',
      guideLocation: {
        title: 'Localidade em que você atua',
      },
      guideIdioms: {
        title: 'Idiomas que tem fluência',
        otherLanguages: 'Outros',
      },
      guideSpecialties: {
        change: 'Deseja alterar suas especialidades?',
        tellABitLabel:
          'Em um dos idiomas que você domina, relate um pouco sobre você. Ex: seu tempo de formação e experiência profissional, suas especialidades na região onde trabalha, número de clientes atendidos.',
      },
      financialData: {
        title: 'Valor/hora',
        bankTitle: 'Dados bancários',
        bankSubtitle: 'Onde você receberá pelos seus serviços internacional.',
        bankBrazilAccount:
          'Possui uma conta bancária no Brasil? Informe abaixo.',
        onlineConsultation: 'Encontro online - Reunião virtual',
        valueHourRequired: 'Valor/hora é obrigatório',
        presencialTour: 'Tour Guiado Presencial',
        presentialConsultation:
          'Encontro Presencial - Tour Guiado (limite máximo de 06 pessoas)',
        valueHour: 'Valor/hora',
        valueHour2person: 'Valor até 2 pessoas',
        valueHour3person: 'Valor até 3 pessoas',
        valueHour4person: 'Valor até 4 pessoas',
        valueHour6person: 'Valor até 6 pessoas',
        valueFourHours: 'Valor até 4h',
        valueSixHours: 'Valor até 6h',
        valueEightHours: 'Valor até 8h',
        valueTenHours: 'Valor até 10h',
        bankAccount: {
          bankNumber: 'Número do banco',
          bankName: 'Nome do banco',
          agencyNumber: 'Número da agência',
          accountNumber: 'Número da conta',
          swiftCode: 'Código SWIFT',
          ibanCode: 'Código IBAN',
          bank: 'Banco',
          agency: 'Agência',
          account: 'Conta',
          digit: 'Dígito',
          bankRequired: 'Banco é obrigatório',
          agencyRequired: 'Agência é obrigatório',
          accountRequired: 'Conta é obrigatório',
          digitRequired: 'Dígito é obrigatório',
        },
        stripeTitle: 'Número conta Stripe',
        stripeSubtitle: 'Possui uma conta em nosso parceiro Stripe?',
        stripeAccount: 'Conta Stripe',
        stripeInfoList: {
          text1:
            'O link gerado é de uso único. Caso você já tenha usado o link, recarregue a página para gerar outro link.',
          text2:
            'O Stripe é onde é configurado a conta para receber o dinheiro dos seus serviços. Através dele, pode configurar a conta bancária que deseja receber o dinheiro, além de outras informações.',
        },
        rule1:
          'O pagamento de impostos sobre rendimentos é de sua responsabilidade individual.',
        rule2:
          'O valor do tour presencial deve ser informado considerando todos os custos do guia de turismo (Exemplo: transporte ida e volta+ alimentação).',
        rule3: 'Não haverá pagamento adicional pelo cliente.',
        rule4:
          'Tours presenciais complexos, com custos adicionais à hora do guia de turismo, não poderão ser vendidos através da Contenta360, nessa primeira fase da plataforma',
      },
      videoData: {
        uploadText: 'Arraste e solte um vídeo aqui ou ',
        uploadLink: 'faça um upload',
        rules: {
          time: 'O vídeo de apresentação deve conter até 1:30min',
        },
        success: 'Vídeo enviado com sucesso',
        error: 'Erro ao enviar vídeo',
        sizeError: 'O vídeo deve ter no máximo 200MB',
      },

      membershipData: {
        title: 'Sua assinatura',
        newUserMessage:
          'Bem vindo(a) à Contenta360! Ao ativar a assinatura, será solicitado o seu cartão de crédito, mas você não será cobrado até o final do período de teste. Esperamos que você aproveite a plataforma!',
        cards: 'Cartões cadastrados',
        currencyReceived: 'Moeda para recebimento',
        accountStatus: 'Status da conta',
        acountStart: 'Iniciada em',
        usedCard: 'Cartão que está sendo utilizado',
        nextExpiration: 'Próxima renovação',
        cancelMembership: 'Cancelar assinatura',
        changeCard: 'Alterar cartão',
        actual: 'Atual',
        deactivateMembership: 'Desativar assinatura',
        reactivateMembership: 'Reativar assinatura',
        activateMembership: 'Ativar assinatura',
        cancelMembershipReason: 'Por favor, descreva o motivo do cancelamento',
        selectOption: 'Selecione uma opção',
        options: {
          noMoney:
            'Não tenho condições de arcar com a mensalidade da plataforma, no momento.',
          noClients: 'Não tenho conseguido clientes.',
          noLike: 'Não gostei da plataforma.',
          impediment: 'Estou impedido por motivos de saúde.',
          goActOtherSegment: 'Vou atuar em outro segmento.',
        },
        other: 'Se desejar, faça observações abaixo',
        inactive: 'Inativo',
        canceled: 'Cancelado',
        active: 'Ativo',
        trial: 'Teste',
        newUser: 'Novo usuário',
        table: {
          data: 'Data',
          card: 'Cartão',
          value: 'Valor',
          status: 'Status',
          actions: 'Ações',
        },
      },
      whyAmI: 'Por que eu amo ser um Travel Expert',
      whyAmIQuestion: 'Porque sou um Travel Expert?',
      experience: 'Experiências',
      rating: 'Avaliações',
      investmentValue: 'Valor/hora',
      publications: {
        title: 'Suas publicações',
      },

      schedule: {
        title: 'Agende seu tour agora',
        videoCall: 'Video chamada',
        presential: 'Presencial',
      },

      valueHour: {
        presentialTour: 'Tour presencial',
        onlineConsultation: 'Encontro online - Reunião virtual',
        hour: 'hora',
        untilFourHour: 'até 4 horas',
      },
    },

    resetPassword: {
      success: {
        title: 'E-mail enviado',
        description:
          'Enviamos um e-mail com instruções para redefinir sua senha.',
      },
      error: {
        title: 'Erro',
        description: 'Não foi possível enviar o e-mail.',
      },
    },

    briefing: {
      hour: 'Horário',
      title: 'Informações do Viajante',
      scheduled: 'Agendado para',
      localTime: 'Horário local do viajante',
      chargedValue: 'Valor cobrado',
      scheduledFor: 'Agendamento para',

      travellerInterests: {
        title: 'Interesses gerais do viajante',
      },

      remainingTime: 'Tempo restante para realizar o pagamento:',
      form: {
        success: 'Informações do Viajante enviado com sucesso',
      },

      actions: {
        goHome: 'Ir para a home',
      },
    },

    mentorHome: {
      title: 'Você com autoridade, projeção internacional e lucratividade!',
      seeCompleteAgenda: 'Ver minha agenda completa',
      fullAgenda: 'Agenda completa',
      noScheduleTitle: 'Você ainda não tem nenhuma conversa agendada.',
      noScheduleParagraph:
        'A Contenta360 apresenta o seu perfil de forma inteligente para os viajantes com interesse em sua localidade e especialidades.',
      noScheduleParagraph2:
        'Mantenha seu perfil atualizado e exerça a colaboração enviando seus vídeos para impulsionar a procura pela sua cidade e região.',
      nextScheduleTitle: 'Meus agendamentos confirmados',
      acceptPenalty: 'Aceitar penalidade',
      giveUpCancel: 'Desistir do cancelamento',
      cancelRequest: 'Pedido de Cancelamento',
      subscribeNow: 'Assine agora',
      notMember: {
        title: 'Ops! O seu perfil ainda está em análise',
        paragraph1:
          'Obrigado por fazer o pré-cadastro como travel expert na Contenta360.',
        paragraph2:
          'Para concluir o processo, agende uma entrevista com nossa equipe de curadoria usando o link abaixo.',
        thisLink: 'agende aqui.',
        listLinks: {
          portuguese: 'Agendar em Português',
          english: 'Agendar em Inglês',
          schedule: 'Agendar',
        },
        paragraph3:
          'Caso não encontre um horário adequado, envie um e-mail para partners@contenta360.com, e organizaremos um horário conveniente para você.',
        paragraph4: 'Estamos ansiosos para conhecê-lo!',
        doubts: 'Em caso de dúvidas, entre em contato conosco pelo e-mail ',
      },
      pendingItems: {
        title: 'Você tem pendências no seu perfil',
        guideLicenseURL: 'Licença de Guia de Turismo',
        imageURL: 'Foto de perfil',
        status: 'Status',
        stripeAccountEnabled: 'Conta Stripe',
        videoURL: 'Vídeo de apresentação',
        ycbmAccountConfirmed: 'Confirmar conta YCBM',
        guideLicenseApproved: 'Aprovação da Licença de Guia de Turismo',
      },
      pendingPayments: 'Pagamentos pendentes',
    },

    modal: {
      attention: 'Atenção',
      clickRead: 'Clique aqui e consulte o regulamento',
      cancelSchedule: {
        paragraph1:
          'Cancelar ou reagendar uma vídeochamada com antecedência menor que 24 horas, poderá gerar',
        penaltyAmount: 'penalidade de 20%',
        paragraph2: 'sobre o valor total pago pelo cliente..',
      },

      evaluate: {
        title: 'Avaliar',
        subtitle: 'Sua avaliação é muito importante para nós!',
        traveler: 'Viajante',
        advisor: 'Advisor',
        successMessage: 'Avaliação realizada com sucesso!',
        rateQuestions: {
          interaction: 'Interação e conexão com você',
          content: 'Qualidade do conteúdo do Travel Expert',
          qualityAudio: 'Qualidade de áudio e vídeo',
          punctuality: 'Pontualidade',
          behavior: 'Comportamento na sala',
          otherComments: 'Se desejar, acrescente observações à sua avaliação.',
        },
      },
    },

    register: {
      authGoogleSuccess: 'Login com Google realizado com sucesso!',
      authGoogleSuccessMessage:
        'Por favor, complete o cadastro clicando agora para salvar',
      CPF: 'CPF',
      CPFRequired: 'CPF é obrigatório',
      gender: 'Gênero',
      genderRequired: 'Gênero é obrigatório',
      masculine: 'Masculino',
      feminine: 'Feminino',
      nonBinary: 'Não binário',
      preferNotToSay: 'Prefiro não dizer',
      yes: 'Sim',
      no: 'Não',
      civilStatus: 'Estado civil',
      civilStatusRequired: 'Estado civil é obrigatório',
      single: 'Solteiro(a)',
      married: 'Casado(a)',
      divorced: 'Divorciado(a)',
      widower: 'Viúvo(a)',
      separated: 'Separado(a)',
      partner: 'Companheiro(a)',
      others: 'Outros',
      completeRegister: 'Cadastro',
      successRegistered: 'Confirme seu e-mail',
      successRegisteredMessage: 'Por favor, confirme seu endereço de e-mail.',
      successRegisteredMessage2:
        'Para concluir seu cadastro, verifique sua caixa de entrada e clique no link de confirmação que enviamos para você.',
      successRegisteredMessage3:
        'Se não encontrar o e-mail, por favor, confira sua pasta de spam ou lixo eletrônico.',
      success: 'Cadastro realizado com sucesso!',

      finishProfile: 'Complete seu perfil!',
    },

    sideProfile: {
      changePassword: 'Alterar senha',
      updatePhoto: 'Atualizar foto',
      accountStatus: 'Status da conta',
      professionalProfile: 'Perfil profissional nas redes sociais',
      mobilePhone: 'Celular',
      approved: 'Aprovado',
      active: 'Ativo',
      accountStatusLabel: {
        notPaid: 'Não pago',
        waitingPayment: 'Aguardando pagamento',
        pendingPayment: 'Pagamento pendente',
        notApproved: 'Não aprovado',
        approved: 'Aprovado',
        blocked: 'Bloqueado',
        pending: 'Em análise',
      },
    },

    findAdvisor: {
      scheduleModal: {
        title: 'Reserve agora',
        amount: 'Selecione duração do tour:',
        valueEightHours: '8 horas',
        valueFourHours: '4 horas',
        valueSixHours: '6 horas',
        valueTenHours: '10 horas',
        noPresential: 'Este Advisor não oferece tours presenciais',
        participants: 'Participantes (máximo de 6 pessoas permitidas):',
        notEnoughSlots: 'Não há horários suficientes disponíveis',
      },
    },

    history: {
      searchBar: {
        specificDestiny: 'Busque atendimentos específicos',
        specificDestinyPlaceholder:
          'Utilize nome do advisor ou local da viagem',
        specificDestinyAdvisor: 'Utilize nome do advisor ou local da viagem',
        specificDestinyTraveller: 'Utilize nome do viajante',
        showAppointments: 'Exibir atendimentos',
        past: 'Anteriores',
        future: 'Future',
        virtual: 'Planejamento de viagem',
        presential: 'Tour presencial',
      },
    },

    traveler: {
      home: {
        randomTexts: {
          title1: 'Viaje bem-informado!',
          text1: 'Conecte-se com guias de turismo locais nos 5 continentes.',
          title2: 'Viaje de forma inteligente',
          text2:
            'Reserve suas viagens a partir das dicas de um especialista local.',
          title3: 'Aproveite o seu tempo de forma inteligente!',
          text3: 'Planeje sua viagem com um guia local.',
          title4: 'Planeje a sua viagem com um guia local.',
          text4: 'Receba dicas customizadas e tenha viagens únicas.',
          title5: 'Aproveite mais as suas viagens!',
          text5: 'Planeje com os nossos guias de turismo locais.',
          title6: 'Reserve um tour presencial após sua reunião online.',
          text6: 'Seja recebido no destino pelo mesmo guia de turismo.',
        },
        title: `Planejar a sua viagem de <br/> forma segura, rápida e com <br/> informações customizadas <br/> para as suas necessidades`,
        paragraph1:
          'Nossos especialistas, que moram no destino turístico que você vai visitar, é a pessoa que vive de perto tudo o que você precisa saber. Eles têm autoridade para lhe ajudar nessa fase da sua viagem.',
        paragraph2:
          'Encontre aqui o Advisor que têm a cara da sua próxima aventura, seja bem-vindo ao nosso Fórum! Nossa sala de visitas é virtual, mas cheia de calor humano.',
        preferredDestinyPlaceholder: 'Busque por país, cidade, região',
        preferredMentor: 'Travel Expert',
        myTravelDestiny: 'Destino',
        virtual: 'Chamada por Vídeo',
        inPerson: 'Tour guiado no seu destino de viagem',
        knowTravelAdvisor: 'Já sei com qual Travel Expert eu quero falar',
        preferredMentorPlaceholder: 'Busque pelo nome',
        meetingMentor: 'O encontro com meu Travel Expert será',
        notTalkedMentor: 'Você ainda não conversou com nossos Advisors?',
        meetMentors: 'Conheça alguns de nossos Travel Experts',
        findSpecialty: 'Buscar por especialidade do Travel Expert',
        specialtiesModalTitle: 'Especialidades',
        specialtiesModalButton: 'Buscar especialidades',
        internationalPurchase: 'Compra internacional',
        priceSubjectChange: 'Valor sujeito a alteração cambial e impostos.',
        virtualLabel: 'Chamada de vídeo - Planejamento de viagem online',
        inPersonLabel: 'Tour guiado no seu destino de viagem',
      },

      scheduleBriefing: {
        minimumCharge:
          'Será cobrado uma taxa administrativa de $value no cartão de crédito',
        giftCardError: 'Gift card inválido',
        giftCardSuccess: 'Gift card aplicado com sucesso',
        subtotal: 'Subtotal',
        discount: 'Desconto (Gift Card)',
        totalPayment: 'Total a pagar',
        hasSomegiftCard: 'Você tem algum Gift Card?',
        giftCard: 'Voucher de desconto (Gift Card)',
        invalidCardName: 'Nome do titular do cartão inválido',
        saveCreditCard: 'Salvar cartão de crédito',
        requiredField: 'Campo obrigatório',
        dateRequired: 'Data é obrigatória',
        title: 'Briefing de Agendamento',
        paymentTitle: 'Pagamento',
        investmentValue: 'Preço',
        paymentDetailsSubtitle: 'Detalhamento da compra',
        paymentDetailsSubtitleGiftCardWithoutTravel:
          '*O Travel Expert será escolhido por ${GIFTED_NAME}, no dia e horário',
        paymentAddress: 'Endereço de cobrança',
        paymentYourAddress: 'Seu endereço',
        paymentDetails: 'Detalhes do pagamento',
        paragraph1:
          'Para proporcionar um melhor aproveitamento do tempo da sua vídeo chamada com o Advisor, preparamos um rápido questionário sobre o seu perfil, favor responder com a maior clareza possível.',
        paragraph2: 'Essas informações serão compartilhadas com o seu Advisor.',
        youWillTravel: 'Você viajará',
        withFamily: 'Com a família',
        withFriends: 'Grupo de amigos',
        withPartner: 'Casal',
        withAlone: 'Sozinho',
        expectedDateTitle: 'Você já tem uma data provável para viajar?',
        hasKids: 'Com crianças menores de 15 anos?',
        ageRange1: '0 a 2 anos',
        ageRange2: '3 a 5 anos',
        ageRange3: '6 a 10 anos',
        ageRange4: '11 a 15 anos',
        ageKids: 'Se for criança, qual a faixa etária?',
        describeHere: 'Descreva aqui',
        yourAddress: 'Seu endereço de cobrança',
        specialDate: {
          title: 'Você estará comemorando alguma data especial?',
          birthday: 'Aniversário',
          anniversary: 'Aniversário de casamento',
          honeymoon: 'Lua de mel',
          vacation: 'Férias',
          kidsVacation: 'Férias das crianças',
          remoteWork: 'Trabalho remoto',
          digitalNomad: 'Nomade digital',
          others: 'Outros',
        },
        specialNeeds: {
          title:
            'Você ou alguém que viaja com você é portador de necessidades especiais?',
          tellUs: 'Conte um pouco sobre essas necessidades',
        },
        theme: {
          title:
            'Conte um pouco mais ao seu especialista sobre os temas que você pretende abordar em sala.',
          addTheme: 'Adicionar tema',
        },
        probableDate: {
          probableDate: 'Você já tem uma data provável para viajar?',
          probableDateText:
            'Essas informações orientam o seu Travel Expert e ajudam no planejamento.',
          departureDate: 'Data de saída',
          from: 'De',
          origin: 'Origem',
          to: 'Para',
          destiny: 'Destino',
          airplane: 'Avião',
          transportation: 'Meios de transporte',
          duration: 'Duração da viagem',
          add: 'Adicionar mais destinos',
          day: 'Dia',
          days: 'Dias',
        },
        expectations: {
          title:
            'Quais são as principais expectativas para a sua próxima viagem?',
          label:
            'Ex: Viver a cidade como um local, ficar no campo, praticar esportes, aventuras, gastronomia, experimentar vinhos, cultura, espiritualidade.',
          placeholder: 'Descreva aqui',
        },
        confirmedPayment: {
          paymentProcessed: 'O seu pagamento foi processado.',
          title: 'Pagamento confirmado',
          paragraph1:
            'O seu pagamento foi processado. Que tal já preencher as Informações do Viajante para o seu Travel Expert atender-lhe melhor?',
          rescheduled:
            'O seu pagamento foi processado, e a reunião foi reagendada no sistema.',
          button: 'Voltar para página inicial',
          goAhead: 'Prosseguir',
          briefing: 'Preencher Informações do Viajante',
        },
        editedMembership: {
          title: 'Assinatura editada',
          paragraph1: 'A sua assinatura foi editada com sucesso',
        },
        failedPayment: {
          title: 'Identificamos uma falha no seu pagamento',
          subtitle: 'O seu pagamento não foi processado, tente novamente.',
          paragraph1:
            'Não se preocupe, caso precise sair do site no momento você poderá realizar o pagamento posteriormente.',
          payOtherTime: 'Pagar depois',
          goAhead: 'Prosseguir',
        },
        noNeighborhood: 'Bairro não informado',
        travelMotivation: {
          title: 'Motivo da viagem',
          backpacker: 'Mochileiro',
          economic: 'Econômico',
          intermediate: 'Intermediário',
          luxury: 'Luxo',
          higher: 'Superior',
          intermediary: 'Intermediário',
          lux: 'Luxo',
          other: 'Prefiro não dizer',
          preferNotToSay: 'Prefiro não dizer',
          budget: 'Qual o seu orçamento de viagem?',
          leisure: 'Lazer',
          business: 'Negócios',
          leisureAndBusiness: 'Lazer e negócios',
          hostel: 'Hostel',
          superior: 'Superior',
          luxuryHotel: 'Hotel de luxo',
          inexpensive: 'Barato',
          moderate: 'Moderado',
          expensive: 'Caro',
          veryExpensive: 'Muito caro',
          superLuxuryHotel: 'Hotel super luxo',
          restaurantRecommendation: 'Recomendação de restaurante',
          intermediateRestaurant: 'Restaurante intermediário',
          refinedRestaurant: 'Restaurante requintado',
          hauteCuisineRestaurant: 'Restaurante de alta gastronomia',
          hotelRecommendation: 'Recomendação para hotéis',

          birthday: 'Aniversário',
          anniversary: 'Aniversário de casamento',
          honeymoon: 'Lua de mel',
          vacation: 'Férias',
          kidsVacation: 'Férias das crianças',
          remoteWork: 'Trabalho remoto',
          digitalNomad: 'Nomade digital',
          requiredField: 'Campo obrigatório',
        },
        confirmModal: {
          generalRules: 'Regras gerais',
          titlePresential: 'Encontro presencial - Tour Guiado',
          titleOnline: 'Chamada por Vídeo',
          codeOfConduct: `Li e concordo com o`,
          linkCode: 'código de conduta',
          ofPlatform: 'da plataforma',
          contactEmail: 'Dúvidas: contactus@contenta360.com',
          advisor: 'Travel Expert:',
          cancelReschedule: 'Para cancelamentos e reagendamentos',
          whereMeeting: 'Onde será a reunião?',
          cancelNoPenalty:
            'Sem multa se cancelado ou reagendado com antecedência maior que 48 horas.',
          cancelPenalty:
            'Multa de 100% do valor pago se cancelado ou reagendado com antecedência menor que 48 horas.',

          onlineMeeting: {
            whereMeeting: 'Onde será a reunião?',
            reservedTime: 'Tempo reservado',
            meetingInfo1:
              'Sua reunião foi agendada para as 11:00 do dia 16 de setembro de 2023. Duração: 01 hora',
            meetingLocation:
              'O link para webconferência é enviado após a confirmação do pagamento e reserva.',
            generalRules: 'Regras gerais',
            briefing:
              'Após efetuar o pagamento preencha as Informações do Viajante da sua viagem com informações para o seu Travel Expert lhe atender melhor.',
            allowedParticipants:
              'Permitimos até 4 pessoas na sala de vídeoconferência.',
          },

          presentialMeeting: {
            reservedTime: 'Tempo reservado',
            meetingInfo1:
              'Encontro presencial agendado para as 11:00 do dia 16 de setembro de 2023. Pacote de 03 horas',
            meetingInfo2:
              'O Chat para conversar com o seu Travel Expert estará aberto 48 horas antes do início do seu tour.',
            meetingLocation:
              'O link para webconferência é enviado após a confirmação do pagamento e reserva.',
            generalRules: 'Regras gerais',
            afterPayment:
              'Após efetuar o pagamento preencha as Informações do Viajante da sua viagem.',
          },
        },

        alreadyParticipate: {
          title:
            'Você já participou de uma Chamada por Vídeo com o Travel Expert ${ADVISOR_NAME}?',
          text: 'Nós recomendamos que seja feita uma Chamada por Vídeo prévia ao encontro presencial para que ambos (Traveler e Travel Expert) se conheçam.',
          required: 'Campo obrigatório',
        },
        meetingPoint: {
          title: 'Ponto de encontro',
          hotelName: 'Nome do hotel em que está hospedado',
          hotelRequired: 'Hotel é obrigatório',
          address: 'Endereço',
          addressRequired: 'Endereço é obrigatório',
        },
        wishTour: {
          title: 'Como você deseja que seja o seu tour?',
          label:
            'Considerando o que foi dito na sua reunião de planejamento, descreva para o seu Travel Expert, como você deseja que seja o seu Tour.',
          placeholder: 'Descreva aqui',
        },
        placesVisit: {
          title: 'Que atrações você gostaria muito de visitar?',
          placeholder: 'Descreva aqui',
        },
        requiredPoint: {
          title: 'Existe algum ponto de parada obrigatória?',
          label: 'Informe o ponto obrigatório',
          addrequiredPoint: 'Adicionar ponto',
        },
        childrenOrOldPeople: {
          title: 'No seu grupo há crianças ou idosos?',
          required: 'Campo obrigatório',
        },
        restrictionFood: {
          title: 'No seu grupo há alguém com restrição alimentar?',
          label: 'Informe quais são as restrições',
          placeholder: 'Descreva aqui',
        },
        rulesModal: {
          hours: 'horas',
          hour: 'hora',
          online: {
            title: 'Chamada por Vídeo',
            advisor: 'Travel Expert',
            meetingDuration: 'Tempo reservado',
            scheduleDate: 'Sua reunião foi agendada para as',
            scheduleDateComplement: 'do dia',
            duration: 'Duração',
            chatDescription: '',
            meetingLocationInfo: 'Onde será a reunião',
            locationDetails:
              'O link para webconferência é enviado após a confirmação do pagamento e reserva.',
            locationDetails2: '',
            generalRules: 'Regras gerais',
            rule1:
              'Após efetuar o pagamento preencha as Informações do Viajante da sua viagem com informações para o seu Travel Expert lhe atender melhor.',
            rule2: 'Permitimos até 4 pessoas na sala de vídeoconferência.',
            rescheduleTitle: 'Para cancelamentos e reagendamentos',
            rescheduleRule1:
              'Sem multa se cancelado ou reagendado com antecedência maior que 48 horas.',
            rescheduleRule2:
              'Multa de 100% do valor pago se cancelado ou reagendado com antecedência menor que 48 horas.',
            confirmationButton: 'Aceitar',
            terms1: 'Li e concordo com o',
            terms2: 'código de conduta',
            terms3: 'da plataforma.',
            contact: 'Dúvidas: contactus@contenta360.com',
          },
          presential: {
            title: 'Encontro presencial - Tour Guiado',
            advisor: 'Travel Expert',
            meetingDuration: 'Tempo reservado',
            scheduleDate: 'Encontro presencial agendado para as',
            scheduleDateComplement: 'do dia',
            duration: 'Pacote de',
            chatDescription:
              'O Chat para conversar com o seu Travel Expert estará aberto 48 horas antes do início do seu tour.',
            meetingLocationInfo: 'Onde será a reunião',
            locationDetails:
              'O ponto de encontro deverá ser informado e/ou confirmado 48 horas antes do seu tour.',
            locationDetails2:
              'Informe nas Informações do Viajante o número de pessoas que farão o tour com você. Máximo permitido: 06 pessoas.',
            generalRules: 'Regras gerais',
            rule1:
              'Após efetuar o pagamento preencha as Informações do Viajante da sua viagem.',
            rule2: '',
            rescheduleTitle: 'Para cancelamentos e reagendamentos',
            rescheduleRule1:
              'Sem multa se cancelado ou reagendado com antecedência maior que 72 horas.',
            rescheduleRule2:
              'Multa de 100% do valor pago se cancelado ou reagendado com antecedência menor que 72 horas.',
            confirmationButton: 'Aceitar',
            terms1: 'Li e concordo com o',
            terms2: 'código de conduta',
            terms3: 'da plataforma.',
            contact: 'Dúvidas: contactus@contenta360.com',
          },
        },
        processingPayment: {
          title: 'Processando pagamento',
          text: 'Aguarde enquanto processamos seu pagamento',
        },
      },

      historyCalls: {
        title: 'Histórico de chamadas',
        futureCalls: 'Agendamentos futuros',
        pastCalls: 'Agendamentos passados',
        noFutureCalls: 'Você não possui agendamentos futuros',
        noPastCalls: 'Você não possui agendamentos passados',
        foundResults: 'Resultados encontrados',
        noResults: 'Nenhum resultado encontrado',
        clearFilters: 'Limpar filtros',
      },

      resume: {
        title: 'Resumo',
        yourSpecialties: 'Suas especialidades',
        attendmentArea: 'Área de atendimento',
        specialities: 'Especialidades',
        downloadChat: 'Clique para baixar o histórico do chat dessa reunião',
        yourGuideNotes: 'Anotações do seu guia',
        noNotes: 'O guia não preencheu anotações',
        yourInterests: 'Seus interesses',
        experience: 'Experiência',
        hotels: 'Hotéis',
        description: 'Descrição',
        scheduledFor: 'Agendado para',
        localTimeTraveller: 'Horário local do viajante',
        value: 'Valor',
        guideNotes: 'Anotações do seu guia',
        packages: {
          title: 'Gostou da reunião? Contrate mais horários',
          onlineMeeting: 'Encontro online - Reunião virtual',
          presentialMeeting: 'Tour Presencial',
          package1h: 'Valor por hora: ',
          package4h: 'Pacote de 4h: ',
          package6h: 'Pacote de 6h: ',
          package8h: 'Pacote de 8h: ',
          package10h: 'Pacote de 10h: ',
        },
        noBriefing: 'O viajante não preencheu as Informações do Viajante',
        interestedIn: 'Interesses do viajante',
        travelReason: 'Motivo da viagem',
        budget: 'Orçamento da viagem',
        hotelRecommendation: 'Recomendação de hotéis',
        restaurantRecommendation: 'Recomendação de restaurantes',
        topicsAddressMeeting: 'Temas',
        expectation: 'Principais expectativas para a viagem',
        probableDateTravel: 'Data provável para viajar',
        withWhoGoTravel: 'Número e relação dos viajantes',
        specialDate: 'Data especial',
        hasSpecialNeed: 'Alguém com necessidades especiais?',
      },
    },

    filtersTag: {
      beach: 'Praia',
      mountain: 'Montanha',
      urban: 'Urbano',
      cruise: 'Cruzeiro',
      resort: 'Resort',
      gastronomy: 'Gastronomia',
      wine: 'Vinho',
      adventure: 'Aventura',
      ecotourism: 'Ecoturismo',
      shopping: 'Compras',
      culture: 'Cultura',
      adrenaline: 'Adrenalina',
      sports: 'Esportes',
    },

    toast: {
      pictureSaved: 'Foto salva com sucesso',
      emailSent: 'E-mail para alteração de senha enviado com sucesso',
      errorEmailSent: 'Erro ao enviar e-mail',
    },

    meeting: {
      reloadModal:
        'Ops! Alguma coisa não correu bem. Por favor, recarregue a página e tente novamente!',
      advisorNotShow:
        'Prezado {travelerName}, <br /><br />' +
        'Parece que o seu Travel Expert teve algum problema que o impediu de entrar na reunião. <br />' +
        'Lamentamos muito e entraremos em contato com você em breve para maiores esclarecimentos. <br />' +
        'A reunião será encerrada e vamos processar o reembolso integral do valor pago por esta reunião de vídeo chamada. <br /><br />' +
        'Atenciosamente, <br />' +
        '<b>Contenta360</b>',

      buttons: {
        copy: 'Link',
        waitingLocation: 'Aguardando ponto de encontro',
        seeMeetingPoint: 'Ver ponto de encontro',
        selectCamera: 'Selecionar câmera',
      },

      modalTimeNearToEnd: {
        title: 'Tempo de reunião quase acabando',
        description:
          'O tempo de reunião está quase acabando. Use esse tempo para finalizar a conversa e agendar uma nova reunião se necessário.',
      },

      recordNotes: {
        copy: 'Copie o link da chamada para compartilhar com o seu grupo de viagem',
        message:
          'Esta reunião está sendo gravada e o conhecimento compartilhado nela é propriedade intelectual do apresentador. A gravação e captura de imagens da tela estão estritamente proibidos sem a permissão prévia por escrito do apresentador ou da Contenta360. A violação destas diretrizes pode resultar em medidas legais e outras ações apropriadas.',
        title: 'Aviso de Gravação, Confidencialidade e Propriedade Intelectual',
        text1:
          'Esta reunião será gravada e o conhecimento compartilhado nela é propriedade intelectual do apresentador.',
        text2:
          'A gravação e captura de imagens da tela estão estritamente proibidos sem a permissão prévia por escrito do apresentador ou da Contenta360. A violação destas diretrizes pode resultar em medidas legais e outras ações apropriadas.',
      },

      notification: {
        captions: 'Transcrição automática ao vivo ativada!',
        copy: {
          title: 'Sucesso',
          message: 'Link copiado',
        },
      },

      loading: 'Entrando na Sessão...',

      lobby: {
        title: 'Olá visitante, bem-vindo!',
        text: 'Por favor, digite o seu nome para acessar a reunião',
        input: { placeholder: 'Digite Aqui' },
        button: {
          send: 'Entrar',
        },
      },

      preview: {
        disclaimer: {
          paragraph:
            'Esta reunião será gravada e o conhecimento compartilhado nela é.<br/><br/>propriedade intelectual do apresentador. A gravação e captura de imagens<br/>da tela estão estritamente proibidos sem a permissão previa por escrito<br/>do apresentadorou da Contenta360˚. A Violação destas diretrizes<br/>pode resultar em medidas legais e outras ações apropriadas.',
        },
        buttons: {
          tooltips: {
            audio: {
              select: 'Selecionar Audio',
              start: 'Iniciar Audio',
              end: 'Encerrar Audio',
            },
            camera: {
              select: 'Selecionar Camera',
              start: 'Iniciar Camera',
              end: 'Encerrar Camera',
            },
            screen: {
              select: 'Selecionar Tela',
              share: 'Compartilhar Tela',
            },
            closeChat: 'Fechar Chat',
            openChat: 'Abrir Chat',
            liveTranscription: 'Transcrição ao vivo',
          },
          ready: 'Entrar na reunião',
        },
      },
      video: {
        notification: {
          endCall: {
            message: 'Reunião Finalizada',
            description: 'Esta reunião foi encerrada pelo anfitrião',
          },
        },
        languages: {
          EN: 'Inglês',
          ZH: 'Chinês (simplificado)',
          JA: 'japonês',
          DE: 'Alemão',
          FR: 'Francês',
          RU: 'Russo',
          PT: 'Português',
          'PT-BR': 'Português',
          ES: 'espanhol',
          KO: 'coreano',
          IT: 'italiano',
          EN1: 'Reservado',
          VI: 'vietnamita',
          NL: 'holandês',
          UK: 'ucraniano',
          AR: 'árabe',
          BN: 'Bengali',
          'ZH-HANT': 'Chinês (tradicional)',
          CS: 'Checa',
          ET: 'estoniano',
          FI: 'finlandês',
          EL: 'grego',
          HE: 'Hebraico',
          HI: 'Hindi',
          HU: 'húngaro',
          ID: 'indonésio',
          MS: 'Malaio',
          FA: 'Persa',
          PL: 'polonês',
          RO: 'Romeno',
          SV: 'sueco',
          TA: 'Tâmil',
          TE: 'Télugo',
          TL: 'Tagalo',
          TR: 'Turco',
        },
        buttons: {
          endSession: 'Finalizar Sessão',
          exitSession: 'Sair da Sessão',
          exitingSession: 'Saindo...',
          tooltips: {
            audio: {
              select: 'Selecionar Audio',
              start: 'Iniciar Audio',
              end: 'Encerrar Audio',
            },
            camera: {
              select: 'Selecionar Camera',
              start: 'Iniciar Camera',
              end: 'Encerrar Camera',
            },
            shareScreen: {
              select: [
                'travar Tela',
                'Um participante pode compartilhar por vez',
                'multiplos participantes podem compartilhar simultaneamente',
              ],
            },
            recording: {
              start: 'começar a gravar',
              pause: 'pausar gravação',
              resume: 'continuar gravando',
              status: 'Status da gravação',
              stop: 'encerrar gravação',
            },
            chat: 'chat da sessão',
            leave: 'Finalizar sessão',
          },
        },
      },
    },

    chat: {
      presentialTourData: 'Dados do Tour Presencial',
      reportAdvisor: 'Denunciar',
      startAChat: 'Inicie uma conversa',
      endedTour: 'Tour realizado',
      noChatSelected: 'Nenhum chat selecionado',
      reportSuccess: 'Denúncia enviada com sucesso',
      messages: {
        you: 'Você',
      },
      reportUserModal: {
        title: 'Denunciar',
        description:
          'Selecione abaixo, por favor, o motivo da sua denúncia, e não se preocupe, ela ficará anônima. Desde já agradecemos por ajudar a Contenta360 a continuar sendo um ambiente seguro e saudável para a nossa comunidade.',
        extraDescription: 'Se desejar, acrescente um comentário.',
        title1: 'Foto - Identidade Travel Advisor',
        title2: 'Assédio',
        title3: 'Preconceito ou intolerância',
        description1: 'A foto de perfil do Travel Advisor está desatualizada.',
        description2:
          'Durante o tour você se sentiiu incomodado ou assediado? Nesse caso, a sua denúncia precisa ser detalhada, use o espaço abaixo para as suas impressões.',
        description3:
          'Durante o tour você percebeu qualquer ação ou fala que traduza preconceito de qualquer natureza?',
      },
    },

    adminPages: {
      prices: {
        title: 'Tabela de preços',
        table: {
          columns: {
            minimum: 'Mínimo',
            maximum: 'Máximo',
            percent: 'Percentual',
            operation: 'Ação',
          },
          areYouSure: 'Tem certeza que deseja cancelar a edição este item?',
          areYouSureDelete: 'Tem certeza que deseja excluir este item?',
          addNewVariant: 'Adicionar nova variação',
        },
      },
    },
    creditCard: {
      addCreditCard: 'Adicionar cartão de crédito',
      invalidCardName: 'Nome do titular do cartão inválido',
      cardHolder: 'Nome impresso no cartão',
      cardHolderPlaceholder: 'Nome como aparece no cartão',
      cardNumber: 'Número do cartão',
      expiryDate: 'Data de expiração',
      cvc: 'Código de segurança',
      emptyCardNumber: 'Número de cartão vazio',
      invalidCardNumber: 'Número de cartão inválido',
      emptyExpiryDate: 'Data de expiração inválida',
      monthOutOfRange: 'O mês de expiração deve estar entre 01 e 12',
      yearOutOfRange: 'O ano de expiração não pode estar no passado',
      dateOutOfRange: 'A data de expiração não pode estar no passado',
      invalidExpiryDate: 'Data de expiração inválida',
      emptyCVC: 'Código de segurança inválido',
      invalidCVC: 'Código de segurança inválido',
      saveCreditCard: 'Salvar cartão para compras futuras',
      finishPayment: 'Finalizar pagamento',
      finishingPayment: 'Finalizando pagamento...',
      membershipPayment: 'Pagamento da assinatura',
      editMembership: 'Editar assinatura',
      reloadingPage: 'Recarregando página...',
      edit: 'Editar cartão',
      save: 'Confirmar',
      removeCard: {
        title: 'Remover cartão cadastrado',
        paragraph1: 'Tem certeza que você deseja excluir este cartão?',
        paragraph2:
          'Caso você deseje utilizá-lo no futuro, você precisará preencher todas as informações novamente.',
        button: 'Remover cartão',
      },
      deactivateMembership: {
        title: 'Desativar assinatura',
        paragraph1: 'Tem certeza que você deseja cancelar sua assinatura?',
        paragraph2:
          'Sua conta será paralisada e você não poderá realizar novos atendimentos, <strong>mas poderá consultar os históricos quando quiser</strong>.',
        button: 'Desativar assinatura',
        hasMeetingYetParagraph1:
          'Parece que você ainda possui alguns <strong>atendimentos agendados</strong> e por isso não poderá desativar a sua assinatura.',
        hasMeetingYetParagraph2:
          'Em caso de dúvidas, entre em contato conosco pelo e-mail <a href="mailto:contactus@contenta360.com">contactus@contenta360.com</a>.',
        success: 'Assinatura desativada com sucesso. Atualizando página...',
      },
    },

    aboutUs: {
      homeButton: 'Saiba mais sobre nós',
      subtitle: 'Contenta360: Facilitando o Planejamento de Viagens',
      title: 'Nossa missão',
      mission: {
        title:
          'Nossa missão é tornar o planejamento de viagens mais fácil para todos.',
        paragraph1:
          'Em plena Era Digital e da Inteligência Artificial, trazemos um <b>Toque Humano</b>, com <b>soluções personalizadas</b> para as necessidades dos nossos clientes.',
        paragraph2:
          'A Contenta360 nasceu para os Viajantes Premium, aqueles que valorizam seu tempo e prezam por informações seguras e de qualidade.',
      },
      contenta360: {
        heading: 'Contenta360',
        paragraph:
          'A Contenta360 nasceu para o Viajante Premium, que valoriza seu tempo e deseja ter as melhores informações locais, de forma rápida e segura. Em plena Era Digital e da Inteligência Artificial generativa, oferecemos pessoas, Toque Humano, com soluções personalizadas. <br/> Agende uma videochamada de planejamento de viagem com guias locais licenciados e especialistas em temas do seu interesse. ',
      },
      travelExperts: {
        heading: 'Encontre o seu Travel Expert',
        paragraph:
          'Encontre o seu Travel Expert: Como Viajante, você pode buscar na plataforma os Especialistas em Planejamento de Viagens por destino e especialidade. Eles estão prontos para te ajudar a planejar ou validar o que você já tem em mente.',
        services: [
          'Planejamento de Viagem Online por videochamada, onde você recebe dicas e orientações de um guia local e pode planejar sua viagem com segurança.',
          'Tours personalizados com guias locais no seu destino. O mesmo profissional que lhe ajudou a planejar a viagem, pode te guiar localmente.',
          'E ainda:',
          '<b>Gift Card</b>: Um presente diferente e marcante para quem você ama.',
        ],
      },
      howItWorks: {
        heading: 'Como Funciona',
        steps: [
          'Encontre o seu Travel Expert: Como Viajante, você pode buscar na plataforma os Especialistas em Planejamento de Viagens por destino e especialidade. Eles estão prontos para te ajudar a planejar ou validar o que você já tem em mente.',
          'Passo 1: Videochamada com uma pessoa de verdade',
          'Passo 2: Faça perguntas, esclareça todas as suas dúvidas',
          'Passo 3: Compre sua viagem onde quiser, com tranquilidade e segurança nas escolhas.',
          'Passo 4: Reserve o seu Guia de Turismo na Contenta 360. Seja recebido localmente por quem você já conheceu virtualmente.',
        ],
      },
      contenta360Difference: {
        heading: 'Os diferenciais da Contenta360',
        paragraph:
          'Nossos guias de turismo te recebem localmente como se você estivesse <b>encontrando um amigo</b>. Viajar fica muito mais fácil quando você conhece alguém no seu destino de viagem. <br /> Ter alguém para te dar todas as dicas locais antes de você ir, é <b>muito especial</b>! Somente um local sabe qual é a melhor época para visitar aquele destino turístico, dar dicas sobre segurança, onde se hospedar de acordo com o perfil da sua viagem, a melhor forma de chegar ao seu hotel desde o aeroporto, a melhor hora para pegar certos caminhos e evitar o trânsito ou risco, onde encontrar as melhores comidas e as melhores opções de entretenimento.',
      },
      whoWeAre: {
        heading: 'Quem Somos',
        paragraph:
          'Vamos voltar no tempo, para março de 2020: O mundo estava incerto, a indústria do turismo começava a ouvir falar da pandemia de Covid-19 pela primeira vez, e a cada segundo, o mundo testemunhava os países fechando suas fronteiras e, quase que instantaneamente, paralisando a indústria do turismo.',
        paragraph2:
          'Nossa fundadora, Rosana, viu sua própria empresa e toda a indústria do turismo pararem de repente. Durante aqueles anos difíceis nasceu a ideia de um novo negócio e ela começou a planejar o desenvolvimento da plataforma para oferecer uma nova forma de guias de turismo interagirem com os viajantes para planejarem suas viagens futuras.' +
          '<br/><br/> Com uma visão 360° da jornada do viajante e 30 anos de experiência na indústria do turismo, Rosana sabia qual era a peça que faltava no quebra-cabeça do planejamento de viagens: <b>Conexão Humana e Personalização.</b>' +
          '<br/><br/> Como ex-Diretora do Grupo JTB e com mais de 30 anos trabalhando na indústria de viagens, ela sempre teve uma visão clara sobre a jornada do viajante, do começo ao fim. Ela sabe do que os viajantes precisam e as dificuldades que enfrentam. Construiu a plataforma do zero, autofinanciada, e contou com a ajuda preciosa da equipe Contenta360 para juntar as peças que faltavam no quebra-cabeça do planejamento de viagens, aquelas que os viajantes sentem falta e tanto desejam:',
        paragraph3: 'Conexão Humana + Personalização.',
        paragraph4:
          '“Viajar fica muito mais fácil quando você conhece alguém no seu destino.” - <span>Rosana</span>',
        paragraph5:
          'Leticia Arzuaga, nossa cofundadora, é uma Arquiteta licenciada e registrada que ama viajar e descobrir as maravilhas do nosso planeta. Leticia é apaixonada por design e por resolver problemas através de soluções simples e bonitas. Ela sempre gostou de fazer tours por Chicago com amigos e familiares que a visitavam. Há 23 anos, ela mostrou a cidade para Rosana pela primeira vez, e o resto é história. Leticia ajudou a desenhar o site da Contenta360 e é apaixonada por designs fáceis de usar. Ela tem experiência em acessibilidade pública comercial e busca trazer uma visão inclusiva para o planejamento de viagens, além de trabalhar com nossos parceiros especialistas em destinos acessíveis para promover o turismo inclusivo.',
      },
    },

    privacyPolicy: {
      title: 'Política de Privacidade',
      content: `
<p>A privacidade e a segurança dos dados dos nossos usuários são de suma importância para a Contenta360. Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos as informações pessoais dos usuários da nossa plataforma. Ao utilizar a plataforma Contenta360, você concorda com os termos e condições desta Política de Privacidade.</p>

<div class="terms__subcontainer">
<h3>1. Informações Coletadas</h3>
  <p>1.1 Informações de Registro: Ao criar uma conta na plataforma Contenta360, coletamos informações pessoais fornecidas por você, como nome, endereço de e-mail e senha. Você também pode optar por fazer login usando suas credenciais de login social (por exemplo, Facebook ou Google). Essas informações são necessárias para a criação e gerenciamento da sua conta.</p>
  <p>1.2 Informações de Perfil: Para aprimorar a sua experiência na plataforma, você pode optar por fornecer informações adicionais ao seu perfil, como preferências de viagem, interesses e especialidades. Essas informações são opcionais e ajudam a personalizar as recomendações de guias turísticos de acordo com as suas preferências.</p>
  <p>1.3 Informações de Pagamento: Quando você realiza um pagamento na plataforma Contenta360, coletamos informações de pagamento, como detalhes do cartão de crédito ou outros métodos de pagamento utilizados. Essas informações são coletadas e processadas por meio de provedores de serviços de pagamento terceirizados, que possuem seus próprios termos e políticas de privacidade.</p>
  <p>1.4 Informações de Comunicação: Podemos coletar informações das comunicações que você tem conosco, incluindo e-mails, mensagens de chat e outros tipos de interações na plataforma. Essas informações são necessárias para o atendimento ao cliente e para melhorar a qualidade do serviço.</p>
  <p>1.5 Informações de Uso: Coletamos automaticamente informações sobre como você interage com a plataforma Contenta360, incluindo dados de acesso, registros de atividade, endereço IP, informações sobre dispositivos e navegadores utilizados, cookies e tecnologias semelhantes. Essas informações são usadas para análise de dados, segurança, personalização e aprimoramento contínuo da plataforma.</p>
</div>

<div class="terms__subcontainer">
<h3>2. Uso das Informações</h3>
  <p>2.1 Fornecimento de Serviços: Utilizamos as informações coletadas para fornecer e operar a plataforma Contenta360, incluindo a criação de contas de usuários, conexão entre viajantes e guias turísticos, gerenciamento de reservas, processamento de pagamentos e comunicação entre as partes envolvidas.</p>
  <p>2.2 Personalização e Recomendações: Utilizamos as informações para personalizar a experiência dos usuários na plataforma, fornecendo recomendações de guias turísticos com base nas preferências e interesses registrados nos perfis dos viajantes.</p>
  <p>2.3 Comunicação: Utilizamos as informações de contato fornecidas para enviar notificações relevantes sobre reservas, pagamentos, atualizações da plataforma e outras informações relacionadas ao serviço. Também podemos enviar comunicações de marketing, desde que você tenha dado o seu consentimento prévio para recebê-las. Você pode optar por não receber comunicações de marketing a qualquer momento.</p>
  <p>2.4 Melhoria do Serviço: Utilizamos as informações coletadas para analisar e aprimorar a qualidade da plataforma Contenta360, incluindo o aprimoramento de recursos, segurança, desempenho, usabilidade e personalização.</p>
</div>

<div class="terms__subcontainer">
<h3>3. Compartilhamento de Informações</h3>
  <p>3.1 Com Guias Turísticos (Advisors): Compartilhamos as informações dos viajantes, como nome, datas de reserva e preferências, com os guias turísticos selecionados para a prestação dos serviços contratados. Isso é necessário para permitir que os guias turísticos forneçam as melhores experiências possíveis aos viajantes.</p>
  <p>3.2 Provedores de Serviços: Podemos compartilhar informações com provedores de serviços terceirizados que nos auxiliam na operação da plataforma, como processadores de pagamentos, serviços de hospedagem, análise de dados, serviços de segurança e suporte ao cliente. Esses provedores de serviços têm acesso limitado às informações pessoais necessárias para desempenhar suas funções e são obrigados a manter a confidencialidade dessas informações.</p>
  <p>3.3 Cumprimento Legal e Proteção de Direitos: Podemos divulgar informações pessoais quando exigido por lei, regulamento, processo legal ou solicitação governamental, ou quando acreditarmos de boa-fé que a divulgação é necessária para proteger nossos direitos, segurança ou propriedade, bem como a segurança e propriedade dos nossos usuários.</p>
</div>

<div class="terms__subcontainer">
<h3>4. Segurança dos Dados</h3>
  <p>Levamos a segurança dos dados muito a sério. Implementamos medidas técnicas, administrativas e físicas adequadas para proteger as informações pessoais contra acesso não autorizado, uso indevido, alteração ou divulgação não autorizada. No entanto, nenhum método de transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro, e não podemos garantir a segurança absoluta dos dados.</p>
</div>
<div class="terms__subcontainer">
<h3>5. Retenção de Dados</h3>
  <p>Retemos as informações pessoais dos usuários pelo tempo necessário para fornecer os serviços solicitados e para cumprir com as obrigações legais. Após esse período, as informações serão excluídas ou anonimizadas de forma a não identificar mais o usuário.</p>
</div>

<div class="terms__subcontainer">
<h3>6. Seus Direitos</h3>
  <p>Os usuários da plataforma Contenta360 têm direito a acessar, corrigir, atualizar ou excluir suas informações pessoais, bem como a limitar ou se opor ao processamento dessas informações, de acordo com a legislação aplicável de proteção de dados. Para exercer esses direitos ou caso tenha dúvidas ou preocupações sobre a privacidade e a segurança dos dados, entre em contato conosco por meio das informações fornecidas na seção de contato da plataforma.</p>
</div>

<div class="terms__subcontainer">
<h3>7. Alterações nesta Política de Privacidade</h3>
<p>Podemos atualizar esta Política de Privacidade periodicamente para refletir as práticas e políticas atuais da Contenta360. Recomendamos que você revise esta política regularmente para se manter informado sobre como tratamos as informações pessoais dos usuários.</p>
<p>Data de vigência: Esta Política de Privacidade foi atualizada e entra em vigor a partir dessa data.</p>
</div>
      `,
    },
    termsAndConditions: {
      title: 'Termos e Condições Gerais de Uso',
      content: `<p>A Contenta360, sociedade empresária individual, inscrita no CNPJ/MF sob o nº 42.458.840/0001-42, com sede no Brasil, no Estado de São Paulo, na Cidade de São Paulo, na Al. Lorena, 131, cj. 31, CEP 01424-000, é uma pessoa jurídica de direito privado responsável pela plataforma doravante denominada Contenta360.</p>
      <p>Por intermédio destes Termos e Condições Gerais de Uso (“Termos”), a Contenta360 apresenta aos usuários (“Usuários”) as condições essenciais para o uso dos serviços oferecidos na Plataforma.</p>
      <p>Ao utilizar a Plataforma ou os serviços ofertados pela Contenta360, os Usuários aceitam e se submetem às condições destes Termos e às respectivas Políticas de Privacidade.</p>
        <div class="terms__subcontainer">
        <h2>• Objetivo</h2>
        <p>•	A Contenta360 é uma plataforma de marketplace para conexão entre viajantes (também denominados Usuários) e especialistas em destinos de turismo (também denominados Prestadores), disponibilizando essa plataforma de serviços para agendamento e realização de sessões de consultoria online, bem como para pagamento dessas sessões. A plataforma Contenta360 também oferece possibilidade de agendamento de pacote de horas para realização de passeios guiados no destino turístico.</p>
        <p>•	Portanto, a Contenta360 é responsável apenas e tão somente pela intermediação entre Usuários e Prestador de Serviços, para que os Usuários possam encontrar, agendar, efetuar o pagamento e realizar a sessão online, bem como ferramenta para agendar e efetuar o pagamento de pacote de horas para o tour presencial.</p>
        <p>•	A Contenta360 disponibiliza sala virtual na plataforma, por meio do endereço eletrônico www.contenta360.com, desenvolvido para intermediar consultoria de viagens no formato de vídeo conferência entre Prestador e Usuário.</p>
        <p>•	Os Prestadores de Serviço são os únicos e exclusivos responsáveis pela veracidade e exatidão das informações e conteúdo apresentados na sessão virtual, bem como de seu método de trabalho, ficando a Contenta360 isenta de responsabilidade em razão de cancelamento, atraso, mudança de data ou horário de sessão com Prestador de Serviço ou qualquer outro dano moral ou material ao Usuário, ao qual não tiver dado causa.</p>
        <p>•	Os Usuários/Viajantes, ao utilizarem os serviços, deverão efetuar o pagamento exclusivamente à Contenta360, cabendo a esta última efetuar o repasse aos Prestadores, conforme condições ajustadas entre os referidos Prestadores e a Contenta360, ou seja, o pagamento dos serviços prestados pelos Prestador de Serviço, em formato de consultoria online, deverá acontecer obrigatoriamente através da Plataforma em conjunto com o Sistema de Pagamento Stripe, via cartão de crédito, ou qualquer outra forma de pagamento disponibilizada pela Contenta360. É vedada a utilização ou efetivação de qualquer outro arranjo de pagamento entre Prestador de Serviço e Usuário ao utilizarem os serviços da Contenta360.</p>
        <p>•	Para fins de segurança e garantia dos serviços da Plataforma, a Contenta360 proíbe que Usuários realizem o pagamento diretamente aos Prestadores de Serviço, por exemplo, por meio de depósito bancário ou pagamento direto. De qualquer forma, a Contenta360 não será responsável por eventuais perdas e danos suportados pelo Usuário, por fato atribuível ao Prestador, sendo que este último é o único e exclusivo responsável pelo respectivo atendimento. A Contenta360 é responsável apenas pela intermediação envolvendo o agendamento da sessão e/ou do tour guiado, bem como disponibilização de ferramenta para realização dessas sessões entre Usuário e Prestador de Serviço, além do recebimento e repasses dos valores correspondentes aos serviços.</p>
        <p>•	Caso haja sugestão ao usuário pelo Prestador de Serviço para utilização de meios alternativos de pagamento, em desacordo com o presente Termos de Uso e Política de Privacidade, este deverá comunicar à Contenta360 para que as medidas cabíveis sejam aplicadas.</p>
        <p>•	Prosseguindo com o pagamento das sessões agendadas na Plataforma, presume-se que o Usuário leu e aceitou os Termos de Uso e Política de Privacidade do Sistema de Pagamento, disponíveis no momento de login no sistema. </p>
        <p>•	A Contenta360 se reserva o direito de modificar o Sistema de Pagamento utilizado pela Plataforma, a seu exclusivo critério e a qualquer tempo, avisando previamente o Prestador de Serviço e o Usuário.</p>
        <p>•	Ao se cadastrar, o Usuário poderá utilizar todos os serviços disponibilizados na Plataforma declarando, para tanto, ter lido, compreendido e aceitado os Termos e Condições de Uso.</p>
        </div>

        <div class="terms__subcontainer">
        <h2>• Capacidade para cadastrar-se</h2>
        <p>•	Os serviços da Plataforma estão disponíveis somente para pessoas capazes de exercer pessoalmente os atos da vida civil. O uso por menores de 18 anos está condicionado à presença dos pais ou pessoas maiores de 21 anos na sala virtual, na mesma sessão. Ao acessar, utilizar ou criar uma conta na Contenta360, o Usuário assegura que é capaz de exercer pessoalmente os atos da vida civil e que está ciente das sanções legais previstas na legislação vigente.</p>
        <p>•	É vedada a criação de mais de um cadastro por Usuário. Em caso de multiplicidade de cadastros elaborados por um só Usuário, a Contenta360 se reserva o direito de, a seu exclusivo critério e sem necessidade de prévia anuência ou comunicação aos Prestadores de Serviço ou Usuários, inabilitar todos os cadastros existentes e impedir eventuais cadastros futuros vinculados a estes.</p>
      </div>


      <div class="terms__subcontainer">
        <h2>• Cadastro</h2>
        <p>•	Qualquer Usuário poderá pesquisar e visualizar os Prestadores de Serviço, bem como horário e data disponíveis para agendamento, sem que se registre na Plataforma da Contenta360. Contudo, para agendar uma sessão e usufruir das demais áreas e funcionalidades da Plataforma, o Usuário precisará se cadastrar na Plataforma.</p>
        <p>•	O cadastro será realizado diretamente na Plataforma, mediante o preenchimento dos dados pessoais solicitados pela Contenta360.</p>
        <p>•	Os Usuários declaram estar cientes de que os dados e informações solicitados pela Contenta360 possuem o intuito de realizar a correta e efetiva prestação de serviços disponibilizados pela Plataforma. Para mais informações sobre seus dados, recomendamos a leitura da Política de Privacidade da Contenta360 disponível no link: www.contenta360.com .</p>
        <p>•	A Contenta360 se reserva o direito de recusar qualquer solicitação de cadastro e de cancelar um cadastro previamente aceito, a seu exclusivo critério e sem aviso prévio, não cabendo nenhuma indenização, inclusive na hipótese de impossibilidade de cadastro.</p>
        <p>•	Caso a Contenta360 considere um cadastro, ou as informações nele contidas, suspeito de conter dados errôneos ou inverídicos, a mesma poderá suspender, temporária ou definitivamente, o Usuário responsável pelo cadastramento, sem prejuízo de outras medidas que entender necessárias e oportunas. No caso de aplicação de quaisquer destas sanções, não assistirá aos Usuários o direito a qualquer tipo de indenização ou ressarcimento por perdas e danos, lucros cessantes ou danos morais.</p>
        <p>•	Ao criar uma conta na Contenta360, o Usuário é responsável e se obriga a prover somente informações verdadeiras, exatas e autênticas e a mantê-las atualizadas enquanto estiver utilizando a Plataforma ou serviços da Contenta360. Os Usuários da Contenta360 garantem e respondem, em qualquer caso, civil e/ou criminalmente, pela veracidade, exatidão e autenticidades dos Dados Pessoais cadastrados.</p>
        <p>•	A Contenta360 não se responsabiliza pela veracidade, exatidão, autenticidade ou atualização dos dados dos Usuários e/ou Prestador de Serviço. Entretanto, a Contenta360 se reserva o direito de utilizar todos os meios válidos e possíveis para identificar seus Usuários e/ou Prestador de Serviço, bem como de solicitar dados adicionais e documentos que entenda pertinentes, a fim de conferir os dados pessoais informados, assim como para atendimento das solicitações dos Usuários com relação à Lei Geral de Proteção de Dados.</p>
        <p>•	O Usuário é responsável por proteger sua senha de acesso à Plataforma. Ao criar uma conta na Contenta360, você concorda que não revelará sua senha a terceiros e que você assumirá total responsabilidade por quaisquer ações realizadas através de sua conta, independentemente de ter autorizado ou não tais ações, uma vez que o acesso somente será possível mediante a utilização de sistema de verificação, que é exclusivo para sua conta. </p>
        <p>•	O Usuário se compromete a notificar a Contenta360 imediatamente, por meio dos canais de contato mantidos pela Contenta360 na Plataforma, a respeito de qualquer suspeita ou constatação de uso não autorizado de sua conta.</p>
        <p>•	Estes Termos incluem, mas não se limitam à utilização de funcionalidades “lembrar senha” ou “mantenha-me conectado” em computadores ou dispositivos móveis próprios ou de terceiros.</p>
        <p>•	Em nenhuma hipótese será permitida a cessão, a venda, o aluguel ou outra forma de transferência da conta. Não se permitirá, ainda, a criação de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados por infrações às políticas da Contenta360.</p>
        <p>•	A Contenta360 adota todas as medidas que estão ao seu alcance para proteger os dados e a privacidade do Usuário, em atendimento à legislação em vigor. Para mais informações, acesse nossa Política de Privacidade.</p>
      </div>

      <div class="terms__subcontainer">
        <h2>• Do Agendamento de Sessões online</h2>
        <p>•	A Contenta360 oferece um serviço de gestão de agendamento e pagamento para utilização dos serviços dos Prestador de Serviço. Ressalta-se que a Contenta360 não tem qualquer ingerência sobre a disponibilidade dos Prestadores de Serviço. Portanto, todas as informações sobre data, horário e local são de responsabilidade exclusiva dos Prestadores de Serviço.</p>
        <p>•	O Usuário, já cadastrado, poderá buscar por Prestador de Serviço em Destinos e/ou suas atrações turísticas, atentando-se aos critérios de busca:</p>
        <div class="terms_subcontainer">
          <p>•	País</p>
          <p>•	Cidade</p>
          <p>•	Especialidade</p>
          <p>•	Experiência</p>
          <p>•	Nome</p>
        </div>

        <p>•	Considerando os critérios de informação escolhidos pelo Usuário, será apresentada uma lista de Prestadores de Serviços em Destinos e/ou suas atrações turísticas. Para confirmar disponibilidade de agenda e valor de cada sessão de 1 (uma) hora, o Usuário deverá estar logado na Plataforma. O critério de ordenação dos Prestadores de Serviço em Destinos e/ou suas atrações turísticas, na lista prevista no item 4.2., será definido única e exclusivamente pela Contenta360.</p>
        <p>•	A lista de Prestador de Serviço poderá ser alterada a qualquer momento por decisão exclusiva da Contenta360.</p>
        <p>•	Ressalta-se que o link para acesso à sala virtual será enviado para o e-mail cadastrado e vinculado à conta do Usuário que fizer o agendamento. </p>
        <p>•	A Contenta360 não se responsabiliza por eventuais perdas e danos suportados pela parte prejudicada (em caso de ausência na sessão correspondente).</p>
        <p>•	Antes de efetivar o agendamento, o Usuário deverá optar por uma das formas de pagamento disponíveis na plataforma e efetivar o pagamento antes do respectivo agendamento.</p>
        <p>•	Após a confirmação do agendamento, o horário escolhido pelo Usuário ficará indisponível para os outros Usuários, garantindo assim a não cumulação de sessão virtual em um mesmo horário para um mesmo Prestador de Serviço.</p>
        <p>•	Os Usuários e os Prestadores de Serviço Contenta360 receberão em seus e-mails cadastrados notificações como lembrete de agendamento. O não recebimento ou não leitura das notificações, por qualquer que seja o motivo, não isenta o Usuário da necessidade de se logar na sala, através do link recebido no momento do agendamento.</p>
        <p>•	Os Prestadores de Serviço da Contenta360 e Usuários cadastrados poderão acessar seus agendamentos por meio de painel disponibilizado na Plataforma, em “Minha Conta” > “Agendamentos Ativos”.</p>
        <p>•	Quaisquer atrasos por parte do Usuário e/ou Prestador de Serviço serão de responsabilidade tão somente da parte que deu causa, não sendo, portanto, a Contenta360 responsável por eventuais perdas e danos suportados pela parte prejudicada pelo atraso.</p>
        <p>•	O atraso de até 7 (sete) minutos, seja por parte do Prestador de Serviço ou do Usuário, será considerado aceitável e tolerável. O atraso por parte do Usuário, independentemente dos motivos que o justifiquem, não implicará na extensão da sessão, ou seja, nessa hipótese, o Usuário perderá o tempo correspondente ao atraso. No caso de atraso por parte do Prestador (por mais de 7 minutos), a sessão será encerrada e, nessa hipótese, o Usuário poderá optar, a seu exclusivo critério, pelo reagendamento ou pela restituição integral do valor pago.</p>
        <p>•	A Contenta360 não recomenda comunicação externa entre Prestador e Usuário por meios alternativos de comunicação, nem mesmo para resolução de dúvidas e confirmações de horários das sessões. Para quaisquer comunicações, fica estabelecido que as mesmas deverão ser realizadas exclusivamente pela plataforma da Contenta360.</p>
      </div>


        <div class="terms__subcontainer">
        <h2>• Política de Alteração</h2>
        <p>•	O Usuário poderá alterar o horário da sessão com Prestador de Serviço sem nenhum custo, desde que o faça com até 48 horas de antecedência do início da sessão agendada. Também poderá cancelar a sessão e pedir o ressarcimento integral do valor pago, desde que o faça com mais de 48 horas de antecedência.</p>
        <p>•	Caso o Usuário opte por remarcar a sessão com Prestador de Serviço, será necessário fazer login na Plataforma, indicando novo horário escolhido a partir da disponibilidade de agenda do Prestador de Serviço</p>
        <p>•	Em períodos inferiores a 48 (quarenta e oito) horas, o Usuário perderá o direito de reagendamento ou à devolução do valor pago.</p>
        <p>•	Caso o Usuário faça o agendamento e não compareça no horário marcado ou solicite o cancelamento após o prazo permitido para desistência previsto no item 5.1, o valor cobrado pela sessão com Prestador de Serviço será repassado ao Prestador de Serviço, não sendo restituída qualquer quantia ao Usuário, visto que será considerado como desistência do Usuário em relação à sessão com Prestador de Serviço.</p>
        <p>•	Caso o Usuário deseje receber o reembolso do valor total da sessão (por cancelamento com antecedência igual ou superior a 48 horas), será necessário entrar em contato com a Contenta360 por meio do e-mail contactus@contenta360.</p>
        <p>•	Caso o Usuário tenha realizado o pagamento via cartão de crédito, o valor será reembolsado em até 2 (duas) faturas, de acordo com a data de fechamento da mesma e do processamento pela operadora do cartão de crédito utilizado pelo Usuário. Caso o pagamento tenha sido realizado via PIX ou boleto bancário, o prazo para estorno será de até 10 (dez) dias úteis.</p>
        <p>•	Caso o Prestador, por quaisquer motivos, necessite remarcar a sessão, o mesmo deverá entrar em contato com o Usuário, através da Plataforma, a fim de indicar ao Usuário outro horário que tenha disponibilidade. O Usuário deverá retornar o contato, informando se está de acordo com a remarcação ou se deseja cancelar a sessão com Prestador de Serviço. Caso o Usuário não concorde com a alteração da agenda, a Contenta360 poderá indicar outro Prestador de Serviço ou realizar o reembolso do valor integral ao Usuário, ao exclusivo critério deste último. Caso opte por outro prestador, se sujeitará ao preço da sessão correspondente, devendo ser reembolsado da diferença (se o valor for menor), ou pagar a diferença (caso o preço do novo prestador seja superior ao crédito).</p>
        <p>•	Na hipótese de não comparecimento do Prestador - seja na Chamada por Vídeo ou no tour presencial que vier a ser agendado, o valor cobrado será integralmente devolvido ao Usuário, que poderá, a seu exclusivo critério, utilizar o crédito para agendamento com outro Prestador. Caso o valor cobrado pelo novo prestador seja inferior ao crédito, a diferença deverá ser restituída pela Contenta360. Na hipótese de o valor do prestador escolhido ser superior ao crédito, o Usuário deverá efetuar o pagamento da respectiva diferença.</p>

      </div>
        <div class="terms__subcontainer">
        <h2>• Avaliação dos Prestadores pelos Usuários</h2>
        <p>•	A Contenta360 oferece aos Usuários ferramenta para avaliação dos Prestadores, a fim de contribuir para a qualidade dos serviços prestados. Após a sessão com o Prestador de Serviço, o Usuário poderá avaliá-lo, conforme as seguintes categorias:</p>
        <div class="terms__subcontainer">
          <p>•	Atenção: refere-se à atenção prestada pelo Prestador de Serviço durante toda a sessão.</p>
          <p>•	Pontualidade: Se houve atraso de entrada na sala pelo Prestador de Serviço.</p>
          <p>•	Qualidade conteúdo compartilhado pelo guia: utilidade e aplicação prática.</p>
        </div>

        <p>•	Em cada categoria mencionada no item 6.1 acima, o Usuário poderá atribuir uma nota que varia de 1 (uma) a 5 (cinco) estrelas. A Contenta360 ressalta que tal avaliação será realizada de acordo com os critérios do Usuário, com o objetivo de verificar sua percepção com relação à qualidade da sessão.</p>
        <p>•	Além do mecanismo de avaliação previsto nos itens 6.1 e 6.2, a plataforma disponibilizará um campo para que o Usuário, se assim desejar, escreva um comentário sobre o Prestador, descrevendo a experiência com maior riqueza de detalhes. </p>
        <p>•	O Usuário será o único e exclusivo responsável pelo conteúdo dos comentários, devendo ater-se aos fatos, descrevendo sua opinião de maneira clara e honesta, sendo vedado o uso de linguagem: (i) indecorosa, (ii) preconceituosa, (iii) desrespeitosa, (iv) discriminatória, (v) injuriosa, (vi) caluniosa, (vii) difamatória e/ou que de qualquer forma atentem contra a dignidade, a honra, a moral, a integridade ou qualquer outro direito, independentemente de sua nacionalidade, etnia ou religião, (viii) que contenha dados (mensagens, informação, imagens) subliminares, (ix) contenha dados ou informações que constituam ou possam constituir crime (ou contravenção penal) ou que possam ser entendidas como incitação à prática de crimes (ou contravenção penal); (x) ofenda à liberdade de crença e às religiões, (xi) viole qualquer lei ou seja inapropriada, (xii) atente contra a ordem pública, aos bons costumes e/ou qualquer norma jurídica, (xiii) faça propaganda eleitoral ou divulgue opinião favorável ou contra partido ou candidato e (xiv) viole direitos de Propriedade Intelectual, em especial direitos autorais, de marca e da personalidade.</p>
        <p>•	Os comentários realizados pelos Usuários não têm caráter técnico, sendo seu único objetivo apresentar as percepções pessoais do Usuário durante a sessão com Prestador.</p>
        <p>•	A Contenta360 não fará nenhuma moderação prévia à publicação dos comentários, podendo o Usuário publicar o conteúdo diretamente na Plataforma, sem qualquer interferência da Contenta360. Entretanto, a Contenta360 poderá, a seu exclusivo critério, excluir comentários comprovadamente inverídicos ou que estejam em desacordo com as condições previstas no presente instrumento, notadamente item 6.4.</p>
      </div>


        <div class="terms__subcontainer">
        <h2>• Responsabilidade do Usuário</h2>
        <p>•	O Usuário é responsável e se obriga a:</p>
        <div class="terms_subcontainer">
          <p>•	Reconhecer que todos os acessos realizados na Plataforma, com o uso de login e senha, são interpretados como tendo sido feitos por ele próprio, de forma incontestável;</p>
        <p>•	Dispor de dispositivos, recursos e equipamentos tecnológicos com antivírus e firewall habilitados, softwares devidamente atualizados, além da adoção de medidas de segurança mínimas para acesso à Plataforma;</p>
        <p>•	Deixar seus sistemas de AntiSpam, filtros similares ou configurações de redirecionamento de mensagens ajustados de modo que não interfiram no recebimento dos comunicados e materiais da Contenta360, não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude dos recursos mencionados;</p>
        <p>•	Respeitar todos os direitos de propriedade intelectual de titularidade da Contenta360;</p>
        <p>•	Na incidência de danos decorrentes de atos praticados por meio do acesso à Plataforma ou a terceiros, arcar com todas as obrigações de indenizar o sujeito lesado, assumindo o polo passivo de ação judicial ou procedimento administrativo e requerendo a exclusão da Contenta360, devendo arcar totalmente com as despesas e custas processuais atinentes, deixando-a livre de prejuízos e ônus.</p>
        </div>

        <p>•	As funcionalidades da Plataforma são oferecidas na forma de prestação de serviço, não conferindo ao Usuário nenhum direito sobre o software relacionado à Plataforma ou sobre suas estruturas de informática que a sustentam.</p>
        <p>•	A Contenta360 não recomenda que Usuário faça contato com o Prestador fora da respectiva Plataforma. Em caso de violação desta obrigação, a Contenta360 poderá banir o Usuário e/ou o Prestador, independentemente de qualquer aviso ou notificação, com o consequente cancelamento da(s) Conta(s) correspondente(s), sem prejuízo do dever do Usuário e/ou do Prestador de indenizar a Contenta360 por quaisquer perdas e danos.</p>
      </div>


        <div class="terms__subcontainer">
        <h2>• Isenções e limitações de responsabilidade da Contenta360</h2>
        <p>•	A Contenta360 respeita e obedece a todas as leis e regulamentos aplicáveis às suas atividades e espera que todos seus Usuários e Prestadores façam o mesmo. Entretanto, a Contenta360 não garante e não assume nenhuma responsabilidade pela conformidade de seus Usuários e Prestadores com quaisquer leis ou regulamentos. Ao utilizar a Plataforma ou Serviços da Contenta360, o Usuário declara estar ciente, entender e concordar que deverá respeitar e cumprir todas e quaisquer leis e regulamentos que estiver sujeito por disposição ou imposição dessas mesmas leis e regulamentos.</p>
        <p>•	A Contenta360 não garante a exatidão nem autenticidade das informações e conteúdos fornecidos pelos Usuários e Prestador de Serviço, não tendo nenhuma obrigação de verificá-los ou monitorá-los. Entretanto, a Contenta360 reserva-se o direito de fazê-lo, diretamente ou através de fornecedores ou terceiros capacitados, para garantir a qualidade dos Serviços prestados e a conformidade com estes Termos ou para cumprir com a lei aplicável ou com ordem de algum órgão competente. Estas verificações incluem, mas não se limitam a:</p>
          <div class="terms__subcontainer">
            <p>•	verificar a exatidão, autenticidade e atualidade das informações que vierem a ser prestadas;</p>
            <p>•	verificar antecedentes criminais, de crédito, de reputação online ou qualquer outra informação, com o objetivo de prevenir ou responsabilizar o Usuário ou o Prestador por suspeita da prática de atos lesivos à Contenta360 e/ou a qualquer outro Prestador ou Usuário, bem como para avaliar a capacidade ou probabilidade do Usuário e/ou do Prestador honrarem com as responsabilidades assumidas perante a Contenta360;</p>
            <p>•	acessar, visualizar e monitorar todo e qualquer conteúdo postado ou transmitido pelo Usuário através da Plataforma ou Serviços.</p>
        </div>


        <p>•	O Usuário, ao aceitar o presente Termos de Uso, autoriza expressamente a realização da verificação por quaisquer das formas acima estabelecidas.</p>
        <p>•	A Contenta360 poderá coletar e/ou armazenar as mensagens e diálogos mantidos entre o Usuário e o Prestador, para fins exclusivos de segurança e verificação. A Contenta360 não será responsabilizada por eventuais perdas e danos decorrentes do vazamento ou do uso indevido destes dados e/ou informações, seja pelo Prestador, seja pelo Usuário.</p>
        <p>•	A Contenta360 realiza apenas a intermediação entre Usuário e Prestador, possibilitando que os Usuários encontrem os profissionais desejados e realizem o agendamento de sessão com o Prestador de Serviço. A Contenta360 não endossa nenhum Prestador e não tem nenhuma responsabilidade por eventual conduta ilegal ou inadequada deste último, cabendo apenas excluí-lo em caso de comprovada conduta ilícita ou contrária às disposições dos respectivos termos e condições de uso.</p>
        <p>•	Embora a Contenta360 realize curadoria para seleção dos Prestadores, a mesma não responderá por eventuais informações falsas que vierem a ser fornecidas por esses mesmos Prestadores, cabendo apenas bani-los em caso de comprovada falsidade das informações que envolvam sua identidade, formação, qualificação ou antecedentes.</p>
        <p>•	Em momento algum a Contenta360 poderá ser responsabilizada pelas atitudes e omissões dos Prestadores, visto que apenas disponibiliza ferramenta de intermediação para o agendamento e realização das sessões.</p>
        <p>•	Considerando que a Contenta360 não se responsabiliza por qualquer ato ou omissão de qualquer Prestador, o Usuário declara estar ciente que, ao utilizar a Plataforma ou Serviços, você concorda que qualquer ação judicial ou de responsabilidade civil por ações ou omissões de Prestador, Usuários ou outros terceiros, será limitada a uma reivindicação contra o Prestador, Usuários individuais ou terceiros que lhe causaram mal, concordando em não tentar impor a responsabilidade ou intentar recurso legal contra a Contenta360 ou a seus acionistas, representantes, gestores ou funcionários, com relação a tais ações ou omissões. O mesmo compromisso se aplica a todas as demais partes envolvidas nas reclamações ou ações.</p>
        <p>•	Considerando que a Contenta360 não figura como parte nas sessões que ocorrem entre os Usuários e os Prestadores, a responsabilidade por todas as obrigações delas decorrentes, sejam fiscais, consumeristas ou de qualquer outra natureza, serão exclusivamente dos Usuários e dos Prestadores.</p>
        <p>•	Caso a Contenta360 seja ré em ação judicial ou em processo administrativo cujos fatos sejam atribuídos ao Usuário, este será chamado ao processo, devendo arcar com todos os ônus que der causa.</p>
        <p>•	O Usuário indenizará a Contenta360, suas filiais, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por outros Usuários, Prestadores ou terceiros, decorrentes de atos e omissões atribuíveis a si, ocorridas durante o uso da Plataforma ou Serviços e que impliquem em descumprimento destes Termos de Uso e demais políticas da Contenta360   disponibilizadas na Plataforma; incluindo honorários de advogados.</p>
        <p>•	A Contenta360 não se responsabiliza por lucro cessante ou por qualquer outro dano e/ou prejuízo, de qualquer natureza, que o Usuário possa sofrer devido às interações e transações realizadas ou não através da Contenta360, desde que o referido dano não tenha sido diretamente causado pela Contenta360. Ao utilizar a Plataforma ou Serviços, o Usuário declara estar ciente, entender e concordar que está agindo por sua conta e risco.</p>
        <p>•	A Plataforma ou os Serviços da Contenta360 podem conter links para outros websites. Isto não implica em nenhuma relação de sociedade, de supervisão, de cumplicidade, de endosso ou solidariedade da Contenta360 para com esses sites, seus conteúdos, produtos ou serviços. Ao utilizar a plataforma, o Usuário reconhece e concorda que a Contenta360 não é responsável pela disponibilidade ou exatidão de tais sites e seus respectivos recursos e conteúdos, produtos ou serviços contidos ou disponibilizados através de tais sites ou recursos. O Usuário assume exclusiva e total responsabilidade por todos os riscos e eventuais danos e/ou prejuízos decorrentes do uso de quaisquer desses sites ou de seus conteúdos, produtos ou serviços.</p>
        <p>•	Nenhum conselho, orientação ou informação, verbal ou por escrito, obtidos da Contenta360 ou através da Plataforma, Serviços ou Conteúdos, cria qualquer garantia ou responsabilidade que não tenha sido expressamente assegurada no presente instrumento.</p>
        <p>•	A Contenta360 não se responsabiliza por/pela(o):</p>
        <div class="terms__subcontainer">
          <p>•	Quaisquer vícios, defeitos técnicos e/ou operacionais (problemas, bugs e glitches) ou funcionamentos indevidos que ocorrerem nos dispositivos e equipamentos dos Usuários ou de terceiros e sejam resultantes do uso regular da Plataforma;</p>
        <p>•	Qualquer dano direto ou indireto ocasionado por eventos de terceiros, que não forem causados diretamente pela Contenta360, tal como ataque de hackers, falhas imprevisíveis no sistema, no servidor ou na conexão à Internet, inclusive ocasionado por ações indevidas por terceiros de softwares maliciosos, como vírus, cavalos de Tróia e outros que possam, de algum modo, danificar o equipamento ou a conexão dos Usuários em decorrência do acesso durante a utilização ou navegação na Plataforma, bem como a transferência de dados, arquivos, imagens, textos contidos neste;</p>
        <p>•	Navegação dos Usuários em links externos contidos na Plataforma, sendo seu dever a leitura de eventuais Termos de Uso e Política de Privacidade do portal acessado;</p>
        <p>•	Verificação, controle, aprovação ou garantia a adequação ou exatidão das informações ou dados disponibilizados em tais links, não sendo a Contenta360 responsável, também, por prejuízos, perdas ou danos ocorridos pela visita a tais sites, cabendo ao interessado verificar a confiabilidade das informações e dados ali exibidos antes de tomar alguma decisão ou praticar algum ato;</p>
        <p>•	Disponibilidade integral e ininterrupta da Plataforma, cujo correto funcionamento depende do acesso e tráfego de dados entre terminais de computador dos Usuários e os servidores pertencentes ou contratados pela Contenta360, não possuindo nenhuma ingerência e não sendo responsável por eventuais falhas no tráfego desses dados e no acesso à Plataforma, decorrentes de falhas na rede mundial de computadores ou da própria rede e serviços de telecomunicação prestados por provedores de acesso à Internet;</p>
        <p>•	Erros de sistemas que alterem dados ou informações dos serviços disponibilizados na Plataforma;</p>
        <p>•	Ressarcir seus Usuários por quaisquer gastos com ligações telefônicas, pacotes de dados, SMS, mensagens, e-mails, correspondência ou qualquer outro valor despendido pelo Usuário em razão de contato com a Contenta360 ou quaisquer outros Usuários, por qualquer que seja o motivo.</p>
        </div>


        <p>•	Os Usuários não poderão exigir a disponibilidade da Plataforma conforme suas conveniências. Também não poderão pleitear indenização ou reparação de danos em caso de problema técnico que comprometa o uso da Plataforma. Caso essa indisponibilidade comprometa a realização da sessão, o valor pago será restituído ao Usuário.</p>
        <p>•	A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade da Plataforma em decorrência de alguma reclamação, deverá ser sempre compreendida como demonstração de boa-fé e intenção de solução amigável de conflitos, jamais como reconhecimento de culpa ou de qualquer infração pela Contenta360.</p>
        <p>•	A Contenta360 se reserva no direito de auxiliar e cooperar com qualquer autoridade judicial ou órgão governamental, podendo enviar informações cadastrais de seus Usuários caso assim seja determinado.</p>
      </div>


        <div class="terms__subcontainer">
        <h2>• Propriedade Intelectual</h2>
        <p>•	O uso comercial da expressão "Contenta360" como marca, nome empresarial ou nome de domínio; os conteúdos das telas relativas à Plataforma ou Serviços da Contenta360; e os programas, bancos de dados, logotipos, redes, arquivos que permitem que o Usuário acesse a Plataforma e use Sua Conta, são todos de propriedade da Contenta360 e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos itens são proibidos, salvo por autorização expressa da Contenta360.</p>
        <p>•	O Usuário se compromete a não usar, copiar, adaptar, modificar, preparar trabalhos derivados, distribuir, licenciar, vender, transferir, exibir publicamente, executar publicamente, transmitir ou explorar a Plataforma, Serviços ou Conteúdos, exceto se expressamente permitido nestes Termos. O Usuário também se compromete a não remover, alterar ou ocultar quaisquer direitos autorais, marca registrada, marca de serviço ou outros direitos de propriedade incorporadas ou acompanhando a Plataforma, Serviços ou Conteúdos.</p>
        <p>•	Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas nestes Termos de Uso, tornará o Usuário responsável e passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados.</p>
      </div>


        <div class="terms__subcontainer">
        <h2>• Vedações</h2>
        <p>•	Fica vedada qualquer tentativa, direta ou indireta, bem-sucedida ou não, de realizar alguma das atividades listadas abaixo. A constatação de tal intento tornará o autor, bem como seus mandantes, defensores, encorajadores e ajudantes, passível das sanções previstas nestes Termos, das ações legais pertinentes e da responsabilidade de indenizar quaisquer partes afetadas por eventuais danos causados. Tais atividades incluem, mas não se limitam a:</p>
          <div class="terms__subcontainer">
            <p>•	Incomodar, agredir, caluniar, injuriar, difamar, perseguir ou assediar qualquer outro Usuário, Prestador ou Parceiros;</p>
            <p>•	Infringir os direitos de qualquer pessoa ou entidade, incluindo, sem limitação, os direitos de propriedade intelectual, privacidade, publicidade ou contratual;</p>
            <p>•	Copiar, armazenar, ou de qualquer forma acessar qualquer informação contida na Plataforma ou Serviços ou Conteúdos para fins que não sejam expressamente autorizados por estes Termos;</p>
            <p>•	Utilizar a Plataforma ou Serviços para transmitir, distribuir, publicar ou enviar qualquer informação relativa a qualquer outra pessoa ou entidade, incluindo, sem limitação, fotografias de outras pessoas sem sua permissão, informações de contato pessoal ou de crédito, débito, cartão telefônico ou números de contas;</p>
            <p>•	Manipular os valores ou quaisquer outras informações contidas nos anúncios, na Plataforma ou Serviços;</p>
            <p>•	Utilizar a Plataforma ou Serviços para qualquer fim comercial ou para outros fins que não sejam expressamente autorizados por estes Termos;</p>
            <p>•	Interferir nas transações entre outros Usuários e/ou Prestadores;</p>
            <p>•	Usar software manual ou automatizado, dispositivos, scripts robôs, ou outros meios ou processos para acessar, “scrape”, "crawler" ou “spider” qualquer parte da Plataforma, Serviços ou Conteúdos;</p>
            <p>•	Modificar, copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar, licenciar, criar trabalhos derivados, exibir em outra webpage, utilizar em qualquer outro website, transferir ou vender qualquer informação, software, listas de usuários, banco de dados ou outras listas, produtos ou serviços prestados por ou obtidos pela Contenta360, incluindo, mas não limitado a, práticas envolvendo “screen scraping”, “database scraping”, ou qualquer outra atividade com o propósito de obter listas de usuários ou outras informações;</p>
            <p>•	Usar scripts automatizados para coletar informações ou de outra forma interagir com a Plataforma ou Serviços;</p>
            <p>•	Acessar, testar, adulterar, interferir ou danificar a Plataforma ou qualquer sistema da Contenta360, dos Usuários, dos Prestadores ou de terceiros através do uso de vírus, cancelar bots, cavalos de Tróia, códigos nocivos, flood pings, ataques de negação de serviço, pacote ou falsificação de IP, roteamento forjado ou informações de endereço eletrônico ou métodos similares ou de tecnologia;</p>
            <p>•	Tentar detectar, varrer, ou testar a vulnerabilidade de qualquer sistema ou rede da Contenta360 ou violar qualquer segurança ou medidas de autenticação;</p>
            <p>•	Evitar, ignorar, remover, desativar, prejudicar, decodificar ou evitar qualquer medida tecnológica implementada pela Contenta360 ou qualquer dos provedores Contenta360 ou qualquer outro terceiro (incluindo outro Usuário) para proteger a Plataforma, Serviços ou Conteúdos;</p>
            <p>•	Forjar qualquer pacote de cabeçalho TCP/IP ou qualquer parte da informação do cabeçalho em qualquer publicação, e-mail ou newsgroup, ou de qualquer forma utilizar a Plataforma, Serviços ou Conteúdos para enviar fonte de identificação alterada, enganosa ou falsa informação;</p>
            <p>•	Sistematicamente recuperar dados ou outro conteúdo do nossa Plataforma ou Serviços para criar ou compilar, direta ou indiretamente, em downloads únicos ou múltiplos, a coleta, compilação, banco de dados, diretório ou similar, seja por métodos manuais, através da utilização de bots, crawlers, ou spiders, ou de qualquer outra forma;</p>
            <p>•	Tentar decifrar, descompilar, desmontar ou fazer engenharia reversa de qualquer software usado para fornecer a Plataforma, Serviços ou Conteúdos;</p>
            <p>•	Personificar qualquer pessoa ou entidade, falsificar ou adulterar a si mesmo ou sua afiliação com qualquer pessoa ou entidade;</p>
          </div>
      </div>


        <div class="terms__subcontainer">
        <h2>• Sanções</h2>
        <p>•	Caso algum Usuário não cumpra qualquer dispositivo destes Termos e dos Termos Adicionais ou faça algo que prejudique a Plataforma, a Contenta360, os Prestadores ou outros Usuários, a Contenta360 poderá, a seu exclusivo critério, a qualquer momento e sem aviso ou consentimento prévio:</p>
        <div class="terms__subcontainer">
          <p>•	Suspender ou cancelar definitivamente a Conta do Usuário e todo e qualquer acesso de tal Usuário à Plataforma e aos Serviços.</p>
        <p>•	Tomar ações cabíveis para investigar e julgar violações de qualquer natureza em toda a extensão da lei, podendo envolver ou cooperar com autoridades judiciais e policiais.</p>
        </div>

      </div>


      <div class="terms__subcontainer">
        <h2>• Política de Cancelamento da Conta</h2>
        <p>•	A Contenta360 pode rescindir estes Termos, Contas de Usuários ou acesso dos Usuários à Plataforma e aos Serviços a qualquer momento, sem aviso ou consentimento prévios e a seu exclusivo critério. Nessa hipótese, a Contenta360 se compromete a tomar as providências cabíveis de forma a não prejudicar o Parceiro ou os Usuários.</p>
        <p>•	O Usuário poderá cancelar sua Conta da Contenta360 na própria Plataforma, desde que não tenha nenhuma obrigação pendente em relação a Contenta360 ou a Prestador, incluindo, mas não somente: (i) sessão com Prestador de Serviço em aberto com Usuários e (ii) saldos pendentes a serem resgatados.</p>
        <p>•	Em caso de rescisão:</p>
        <div class="terms__subcontainer">
          <p>•	o Usuário permanecerá responsável pelo pagamento de todos os valores devidos à Contenta360, a Prestadores ou a terceiros;</p>
        <p>•	a Contenta360 verificará eventuais valores que sejam legalmente devidos ao Usuário e lhes pagarão em tempo hábil;</p>
        <p>•	a Contenta360 não assumirá nenhuma responsabilidade por valores devidos por Usuários aos Prestadores.</p>
        </div>

        <p>•	Em caso de cancelamento de Sua Conta, a Contenta360 não realizará imediatamente a exclusão ou devolução de qualquer Conteúdo que você tenha enviado para a Plataforma, incluindo, mas não limitado a quaisquer opiniões, comentários ou depoimentos, sendo respeitados os direitos de pedido de exclusão de dados pessoais, nos termos do presente Termos de Uso e da Política de Privacidade da Plataforma. A Contenta360 mantém os dados pessoais dos Usuários e Prestadores apenas para atendimento às finalidades para as quais são colhidos e para fins de cumprimento de obrigações legais e regulatórias.</p>
        <p>•	O fato de a Contenta360 deixar de exercer ou executar qualquer direito ou disposição destes Termos não constitui uma renúncia à aplicação futura desse direito ou destes Termos. A renúncia de qualquer direito só será eficaz se efetivada por escrito e assinada por um representante devidamente autorizado da Contenta360.</p>
      </div>


        <div class="terms__subcontainer">
        <h2>• Do Canal de Atendimento e Denúncia</h2>
        <p>•	A Contenta360 fornece a seus Usuários e Prestadores um canal de atendimento para críticas, sugestões, denúncias e demais reclamações que o Usuário e/ou Prestador entenda cabível, no seguinte endereço: www.contenta360.com/contato. Ao utilizar este canal, o Usuário ou Prestador deverá se identificar com os dados pertinentes (nome, endereço, número de telefone e e-mail) e descrever a situação detalhadamente, a fim de que a Contenta360   verifique eventuais medidas cabíveis previstas nestes Termos.</p>
      </div>


        <div class="terms__subcontainer">
        <h2>• Notificações</h2>
        <p>•	Quaisquer notificações ou outras comunicações permitidas ou exigidas neste documento, incluindo as modificações em relação a estes Termos, serão por escrito e entregues pela Contenta360 (i) por postagem na Plataforma ou (ii) via e-mail para o endereço de e-mail informado pelo Usuário em Sua Conta.</p>
        <p>•	Quaisquer notificações ou outras comunicações direcionadas à Contenta360 também deverão ser por escrito e entregues por meio dos canais de atendimento disponíveis no seguinte endereço: www.contenta360.com/contato.</p>
        <p>•	Para avisos feitos por e-mail, a data de recebimento será considerada a data em que tal notificação foi transmitida.</p>
      </div>


        <div class="terms__subcontainer">
        <h2>• Legislação Aplicável e Foro de eleição</h2>
        <p>•	Todos os itens destes Termos são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou a qualquer outro questionamento relacionado a estes Termos, as partes concordam e elegem, expressamente, o Foro da Comarca de São Paulo/SP.</p>
      </div>`,
    },

    notTalkedMentor: {
      paragraph1: 'Você ainda não tem reuniões agendadas.',
      paragraph2:
        'Nossos Travel Experts estão esperando por você, prontos para ajudá-lo!',
    },

    withoutMembership: {
      onlineAttend: 'Atenda online',
      presentialAttend: 'Atenda presencialmente',
      definePrice: 'Defina o seu preço',
      personalizeAgenda: 'Personalize sua agenda',
      support24h: 'Suporte técnico 24h',
      paragraph1:
        'O seu cadastro foi aprovado para atuar como Travel Expert no portal Contenta360.',
      paragraph2:
        'Assine o plano mensal de prestadores de serviço da plataforma e comece a captar clientes hoje mesmo!',
      title: 'Boas-vindas {ADVISOR_NAME}!',
      mensalPlan: 'por mês',
      advisorPlan: 'Assinatura Travel Expert',
      subjectToTerms: 'Sujeito a ',
      termsAndConditions: 'Termos e Condições',
      button: 'Assinar agora',
      nophoto: {
        title: 'Adicione uma foto ao seu perfil!',
        paragraph1:
          'Esta é uma etapa importante para que o cliente o identifique quando encontrá-lo pessoalmente.',
      },
    },

    faq: {
      title: 'Como podemos te ajudar?',
      searchPlaceholder: 'Busque por palavras-chave',
    },

    scheduledTours: {
      meetingPoint: 'Ponto de encontro',
      newMeetingPoint: 'Novo ponto de encontro',
      changeMeetingPoint: 'Alterar ponto de encontro',
      errors: {
        meetingPointRejected:
          'Ponto de encontro recusado pelo viajante. Deseja sugerir novo ponto?',
      },
    },

    meetingResume: {
      onlineMeeting: 'Chamada por Vídeo',
      tourSuggestions: 'Sugestões para o tour',
      presentialTour: 'Tour presencial',
      fillMeetingResume: 'Preencha o resumo de reunião',
      suggestionsForTour: 'Sugestões para o tour',
      transportationTips: 'Dicas de transporte',
      securityTips: 'Dicas de segurança',
      restaurantsAndBars: 'Restaurantes/Bares',
      attractionsToBeVisited: 'Atrações que precisam ser visitadas',
      recommendedTours: 'Tours recomendados',
      makeAMeetingResume: 'Faça um resumo da reunião',
      dateTour: 'Data do tour',
      startTour: 'Início do tour',
      endTour: 'Fim do tour',
      zipCode: 'CEP',
      street: 'Rua',
      number: 'Número',
      complement: 'Complemento',
      referencePoint: 'Ponto de referência',
      recommendationsFromYourTravelAdvisor:
        'Recomendações do seu Travel Expert',
      tour1:
        'Tour 1 - Descreva aqui de forma clara um resumo para sugestão do tour',
      tour2:
        'Tour 2 - Descreva aqui de forma clara um resumo para sugestão do tour',
      resumeAboutTour: 'Faça um resumo sobre o tour',
      sendMeetingResume: 'Enviar resumo',
      welcomeTextPresential: `Olá $TRAVELLER_NAME! Estou muito feliz por receber você aqui na minha cidade!`,
      welcomeTextOnline: `Olá $TRAVELLER_NAME! Obrigado pela nossa reunião. Deixo aqui um resumo do que conversamos:`,
    },
  },
};
