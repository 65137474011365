import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from 'antd';

import { v4 as uuidv4 } from 'uuid';

import { Rating } from '@smastrom/react-rating';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import Avatar from 'components/Avatar';

import useRedirectToAdvisorPage from 'hooks/useRedirectToAdvisorPage';

import { FlagsCodeImage } from 'constants/flag-code';
import { customStarsStyles } from 'constants/stars-colors';

import bgImg from 'assets/images/default-city.webp';

import * as S from './styles';

function MentorCard({ mentor }) {
  const { t } = useTranslation();
  const { redirectToAdvisorPage } = useRedirectToAdvisorPage();
  const [showMore, setShowMore] = useState(false);

  const isMobile = window.innerWidth < 1024;

  const handleRedirectToMentor = () => {
    redirectToAdvisorPage(mentor);
  };

  const coverPhotoBg = mentor?.coverURL || bgImg;

  const transformListSpecialties = (specialties) => {
    let listTranslatedSpecialties;
    const hasMore = specialties.length > 4;
    if (showMore) {
      listTranslatedSpecialties = hasMore
        ? specialties
            .slice(0, 5)
            .map((specialty) => t(`specialties.${specialty.name}`))
        : specialties.map((specialty) => t(`specialties.${specialty.name}`));
    } else {
      listTranslatedSpecialties = specialties
        .slice(0, 3)
        .map((specialty) => t(`specialties.${specialty.name}`));
    }

    const grouped = {};

    listTranslatedSpecialties.forEach((name) => {
      const [category, specialty] = name
        .split(' - ')
        .map((part) => part.trim());

      if (!grouped[category]) {
        grouped[category] = [];
      }

      if (specialty) {
        if (!grouped[category].includes(specialty)) {
          grouped[category].push(specialty);
        }
      } else {
        if (!grouped[category].includes(name)) {
          grouped[category].push(name);
        }
      }
    });

    return grouped;
  };

  // const handleSpecialtiesName = (specialties) => {
  //   if (!specialties || specialties.length === 0) {
  //     return '';
  //   }

  //   const listSpecialties = transformListSpecialties(specialties);

  //   const listFormated = Object.entries(listSpecialties)
  //     .map(([category, specialties]) => {
  //       if (specialties.length === 1 && specialties[0] === category) {
  //         return category;
  //       }
  //       return `<b>${category}</b>: ${specialties.join(', ')}`;
  //     })
  //     .join('; ');

  //   const listSize = isMobile ? 150 : 250;
  //   if (listFormated.length > listSize) {
  //     return `${listFormated.slice(0, isMobile ? 150 : 250)}...`;
  //   }

  //   return listFormated;
  // };

  // const specialtiesHTML = useMemo(
  //   () => handleSpecialtiesName(mentor?.specialties),
  //   [mentor?.specialties]
  // );

  return (
    <S.MentorCardContainer>
      <div
        className="mentor-card__cover-photo"
        style={{ backgroundImage: `url(${coverPhotoBg})` }}
      />
      <header className="mentor-card__header" onClick={handleRedirectToMentor}>
        <Avatar src={mentor.imageURL} alt={mentor?.name} />
        <div className="mentor-card__info">
          <h3>{mentor?.name}</h3>
          <span className="mentor-card__country">
            {mentor?.address?.city || mentor?.city} -{' '}
            {mentor?.address?.country?.name ||
              mentor?.country ||
              t('home.countryNotInformed')}
          </span>
        </div>
      </header>

      <div className="mentor-card__body">
        <div className="mentor-card__idioms">
          <span className="mentor-card__idioms-text">
            {t('common.idioms')}:
          </span>
          <span className="mentor-card__idioms-options">
            {mentor?.languages?.map(({ code }) => (
              <Tooltip title={t(`languages.code.${code}`)} key={uuidv4()}>
                {FlagsCodeImage[code]}
              </Tooltip>
            ))}
          </span>
        </div>

        <div className="mentor-card__rating">
          <span className="mentor-card__idioms-text">
            {t('mentorProfile.rating')}
          </span>
          <Rating
            className="evaluate-modal__rate-stars"
            spaceBetween={4}
            style={{ maxWidth: 88 }}
            value={mentor?.rating || 0}
            isDisabled
            itemStyles={customStarsStyles}
          />
        </div>
      </div>

      <div className="mentor-card__button">
        <ContentaButtonPrimary
          onClick={handleRedirectToMentor}
          className="mb-16"
          size="small"
        >
          {t('mentorProfile.expertPage')}
        </ContentaButtonPrimary>
      </div>

      <div className="mentor-card__specialties">
        <span className="mentor-card__specialties-text">
          {t('mentorProfile.specialties')}:
        </span>
        {mentor.topics?.map((topic) => (
          <span className="mentor-card__specialties-options contenta-text">
            {t(`specialties.${topic.name}`)}
          </span>
        ))}
      </div>
    </S.MentorCardContainer>
  );
}

export default MentorCard;
