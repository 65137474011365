import { GiftOutlined } from '@ant-design/icons';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GiftCardModal from '../GiftCardModal';

import * as S from './styles';

function GiftCard() {
  const { t } = useTranslation();
  const [showGiftCardModal, setShowGiftCardModal] = useState(false);

  return (
    <S.GiftCardContainer>
      <p>{t('home.giftText')}</p>
      <ContentaButtonPrimary onClick={() => setShowGiftCardModal(true)}>
        <GiftOutlined />
        {t('home.giftButton')}
      </ContentaButtonPrimary>
      {/* <ContentaButtonPrimary className="btn-rounded">
        <MessageOutlined />
      </ContentaButtonPrimary> */}
      <GiftCardModal
        onCancel={() => setShowGiftCardModal(false)}
        visible={showGiftCardModal}
      />
    </S.GiftCardContainer>
  );
}

export default GiftCard;
