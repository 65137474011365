import { useUser } from '../contexts/userContext';
import { CURRENCIES } from '../constants/currencies';

const useCurrencyParse = () => {
  const { userCurrency } = useUser();

  const parseCurrencyValue = (
    value,
    currency = userCurrency.currencyStandard || userCurrency
  ) => {
    try {
      const country = CURRENCIES.find((c) => c.code === currency);

      const locale = country ? country.countryCode : 'en-US';

      return value.toLocaleString(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    } catch (e) {
      return value;
    }
  };

  return { parseCurrencyValue };
};

export default useCurrencyParse;
