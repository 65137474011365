import * as _ from 'lodash';
import moment from 'moment';

function createAssociatedSince({ mentor = {} }) {
  let { associatedDay, associatedMonth, associatedYear, classAssociation } =
    mentor;

  associatedDay = associatedDay || 1;
  associatedMonth = associatedMonth || 1;
  associatedYear = associatedYear || 2021;

  const day =
    Number(associatedDay) < 10 && associatedDay.length === 1
      ? `0${associatedDay}`
      : associatedDay;
  const month =
    Number(associatedMonth) < 10 && associatedMonth.length === 1
      ? `0${associatedMonth}`
      : associatedMonth;

  if (associatedYear) {
    return `${associatedYear}-${_.padStart(month, 2, '0')}-${_.padStart(
      day,
      2,
      '0'
    )}`;
  }

  return classAssociation?.associatedSince || null; // Lida com undefined
}

function createBirthDate({ mentor = {} }) {
  const { day, month, year, birthDate } = mentor;

  const formattedDay = Number(day) < 10 && day.length === 1 ? `0${day}` : day;
  const formattedMonth =
    Number(month) < 10 && month.length === 1 ? `0${month}` : month;

  if (year) {
    return `${year}-${_.padStart(formattedMonth, 2, '0')}-${_.padStart(
      formattedDay,
      2,
      '0'
    )}`;
  }

  return birthDate || null; // Lida com undefined
}

function handleCustomSpecialty({ customSpecialty }) {
  if (typeof customSpecialty === 'string') {
    return { specialty: customSpecialty };
  }
  return customSpecialty || null;
}

function convertValueInput(value) {
  if (typeof value === 'string') {
    const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));
    return isNaN(numericValue) ? null : numericValue;
  }

  return value || null;
}

function createMentor({ mentor = {} }) {
  return {
    birthDate: createBirthDate({ mentor }),
    currency: mentor.currency || null,
    email: mentor.email || null,
    languages: mentor.languages || [],
    mobilePhone: `${mentor.ddi || ''} ${mentor.mobilePhone || ''}`,
    name: mentor.name || '',
    nationality: mentor.nationality || null,
    summary: (mentor.tellUsAboutYou || '')
      .replace(/,+/g, '٫')
      .replace(/"/g, "'"),
    surname: mentor.surname || '',
    valueHourVirtual: convertValueInput(mentor.valueHourVirtual),
    valueFourHours: convertValueInput(mentor.valueFourHours),
    valueSixHours: convertValueInput(mentor.valueSixHours),
    valueEightHours: convertValueInput(mentor.valueEightHours),
    valueTenHours: convertValueInput(mentor.valueTenHours),
    profilePhrase: mentor.profilePhrase || '',
    password: mentor?.password,
    address: {
      id: mentor?.address?.id || mentor?.addressId || null,
      city: mentor.city || '',
      complement: mentor.complement || '',
      country: { id: mentor.country || null },
      state: mentor.state || '',
      street: mentor.street || '',
      zipcode: mentor.zipcode || '',
    },
    specialties:
      mentor?.specialties?.map((specialty) => ({ code: specialty })) || [],
    ...(mentor.customSpecialty && {
      customSpecialty: { specialty: mentor.customSpecialty },
    }),
    classAssociation: {
      associatedSince: mentor.associatedYear
        ? moment(createAssociatedSince({ mentor })).format('YYYY-MM-DD')
        : '',
      name: mentor.association || '',
      registerNumber: mentor.registerNumber || '',
      guideDependsDescription: mentor.requiredLicenseDetails || null,
    },
    ...(mentor?.isTurismCompany === 'true' && {
      company: {
        document: mentor.documentCompany || '',
        name: mentor.nameCompany || '',
        position: mentor.role || '',
      },
    }),
    consultingRegion: {
      city: mentor.consultancyCity || '',
      country: mentor.consultancyCountry || '',
      details: (mentor.consultancyDetails || '')
        .replace(/,+/g, '٫')
        .replace(/"/g, "'"),
      name: '',
      state: mentor.consultancyState || '',
    },
    customLanguage: {
      language: mentor.otherLanguages || '',
    },
    ...(mentor?.kanaName && {
      kana: {
        city: mentor.city || '',
        complement: mentor.complement || '',
        state: mentor.state || '',
        street: mentor.street || '',
        zipcode: mentor.zipcode || '',
        name: mentor.kanaName || '',
        surname: mentor.kanaSurname || '',
      },
    }),
    ...(mentor?.kanjiName && {
      kanji: {
        city: mentor.city || '',
        complement: mentor.complement || '',
        state: mentor.state || '',
        street: mentor.street || '',
        zipcode: mentor.zipcode || '',
        name: mentor.kanjiName || '',
        surname: mentor.kanjiSurname || '',
      },
    }),
  };
}

function mountMobilePhone({ phone = '', ddi = '' }) {
  if (!ddi && phone) {
    return phone;
  }

  if (phone.includes(ddi)) {
    return phone;
  }

  return `${ddi} ${phone}`.trim();
}

function getCustomLanguage({ mentor = {} }) {
  const isCustomLanguageObject = typeof mentor.customLanguage === 'object';

  if (mentor.customLanguage?.language) {
    return { language: mentor.customLanguage.language };
  }
  if (mentor.otherLanguages || mentor.customLanguage) {
    return isCustomLanguageObject
      ? mentor.customLanguage
      : { language: mentor.customLanguage };
  }

  return null;
}

function updateMentor({ mentor = {} }) {
  const getDefault = (value, fallback) => value ?? fallback;

  const mapAddress = (address = {}, fallback = {}) => ({
    city: getDefault(mentor.city, address.city || ''),
    complement: getDefault(mentor.complement, address.complement || ''),
    country: getDefault(mentor.country, address.country || null),
    id: address.id || fallback.id || null,
    state: getDefault(mentor.state, address.state || ''),
    street: getDefault(mentor.street, address.street || ''),
    zipcode: getDefault(mentor.zipcode, address.zipcode || ''),
  });

  const mapContact = (contact = {}) => ({
    blueSky: getDefault(mentor.blueSky, contact.blueSky || ''),
    facebook: getDefault(mentor.facebook, contact.facebook || ''),
    id: contact.id || null,
    instagram: getDefault(mentor.instagram, contact.instagram || ''),
    site: getDefault(mentor.site, contact.site || ''),
    threads: getDefault(mentor.threads, contact.threads || ''),
    tikTok: getDefault(mentor.tikTok, contact.tikTok || ''),
    twitter: getDefault(mentor.twitter, contact.twitter || ''),
  });

  const mapFinancial = (financial = {}) => ({
    accountNumber: mentor.accountNumber || financial.accountNumber || null,
    address: getDefault(mentor.agencyStreet, financial.address || ''),
    agencyNumber: getDefault(mentor.agencyNumber, financial.agencyNumber || ''),
    bankName: getDefault(mentor.bankName, financial.bankName || ''),
    bankNumber: getDefault(mentor.bankNumber, financial.bankNumber || ''),
    brazilianAccount:
      Number(mentor.brazilianAccount) || financial.brazilianAccount || null,
    brazilianAgency:
      Number(mentor.brazilianAgency) || financial.brazilianAgency || null,
    brazilianBank:
      Number(mentor.brazilianBank) || financial.brazilianBank || null,
    brazilianDigit:
      Number(mentor.brazilianDigit) || financial.brazilianDigit || null,
    city: getDefault(mentor.agencyCity, financial.city || ''),
    country: { id: mentor.accountCountry || financial.country?.id || '' },
    state: getDefault(mentor.agencyState, financial.state || null),
    ibanCode: getDefault(mentor.ibanCode, financial.ibanCode || ''),
    swiftCode: getDefault(mentor.swiftCode, financial.swiftCode || ''),
    zipCode: getDefault(mentor.agencyZipcode, financial.zipCode || ''),
  });

  return {
    address: mapAddress(mentor.address, { id: mentor.addressId }),
    birthDate: createBirthDate({ mentor }),
    createdAt: mentor.createdAt || null,
    currency: mentor.currency || null,
    email: mentor.email || null,
    languages: mentor.languages || [],
    mobilePhone: mountMobilePhone({
      phone: mentor.mobilePhone,
      ddi: mentor.ddi,
    }),
    name: mentor.name || '',
    nationality: mentor.nationality || null,
    profilePhrase: mentor.profilePhrase || '',
    status: mentor.status || null,
    summary: mentor.tellUsAboutYou || mentor.summary || '',
    surname: mentor.surname || '',
    updatedAt: mentor.updatedAt || null,
    valueEightHours: convertValueInput(mentor.valueEightHours),
    valueFourHours: convertValueInput(mentor.valueFourHours),
    valueHourVirtual: convertValueInput(mentor.valueHourVirtual),
    valueSixHours: convertValueInput(mentor.valueSixHours),
    valueTenHours: convertValueInput(mentor.valueTenHours),
    specialties: mentor.specialties || [],
    customSpecialty: handleCustomSpecialty({
      customSpecialty: mentor.customSpecialty,
    }),
    classAssociation: {
      associatedSince: mentor.associatedYear
        ? moment(createAssociatedSince({ mentor })).format('YYYY-MM-DD')
        : '',
      name: mentor.association || mentor.classAssociation.name || '',
      registerNumber:
        mentor.registerNumber || mentor.classAssociation.registerNumber || '',
      guideDependsDescription:
        mentor.requiredLicenseDetails ||
        mentor.classAssociation.guideDependsDescription ||
        null,
    },
    ...(mentor.isTurismCompany === 'true' && {
      company: {
        document: mentor.documentCompany || mentor.company?.document || '',
        name: mentor.association || mentor.classAssociation.name || '',
        position: mentor.role || mentor.company?.position || '',
      },
    }),
    consultingRegion: {
      city: mentor.consultancyCity || mentor.consultingRegion.city || '',
      country:
        mentor.consultancyCountry || mentor.consultingRegion.country || '',
      details:
        (mentor.consultancyDetails || '')
          .replace(/,+/g, '٫')
          .replace(/"/g, "'") ||
        mentor.consultingRegion.details ||
        '',
      name: '',
      state: mentor.consultancyState || mentor.consultingRegion.state || '',
    },
    contact: mapContact(mentor.contact),
    customLanguage: getCustomLanguage({ mentor }),
    ...(mentor.accountNumber && { financial: mapFinancial(mentor.financial) }),
    ...(mentor.kanaName && {
      kana: {
        city: mentor.city || '',
        complement: mentor.complement || '',
        state: mentor.state || '',
        street: mentor.street || '',
        zipcode: mentor.zipcode || '',
        name: mentor.kanaName || '',
        surname: mentor.kanaSurname || '',
      },
    }),
    ...(mentor.kanjiName && {
      kanji: {
        city: mentor.city || '',
        complement: mentor.complement || '',
        state: mentor.state || '',
        street: mentor.street || '',
        zipcode: mentor.zipcode || '',
        name: mentor.kanjiName || '',
        surname: mentor.kanjiSurname || '',
      },
    }),
    imageURL: mentor.imageURL || '',
  };
}

export default { createMentor, updateMentor };
