import { Form, Tabs, Typography } from 'antd';
import { ContentaButtonPrimaryOutline } from 'components/Styled/ContentaButton';
import { CONTENTA_REGISTER_USER } from 'constants/storages-values';
import { useLanguage } from 'contexts/languageContext';
import { useUser } from 'contexts/userContext';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MentorService from 'services/MentorService';
import ParserMentor from 'utils/Parses/ParserMentor';
import { errorHandler } from 'utils/errorHandler';
import errorLogger from 'utils/errorLogger';
import { successHandler } from 'utils/successHandler';
import { FifthStep } from '../FifthStep';
import { FirstStep } from '../FirstStep';
import { FourthStep } from '../FourthStep';
import { SecondStep } from '../SecondStep';
import { ThirdStep } from '../ThirdStep';
import { useRegister } from '../context/useRegister';
import useChangeTabs from './useChangeTabs';

import * as Sentry from '@sentry/react';

const { TabPane } = Tabs;
const { Text } = Typography;

function RegisterSteps() {
  const LAST_STEP = 5;
  const [buttonText, setButtonText] = useState('continue');
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fourthStepForm, updateFourthStepForm] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { specialties: [], customSpecialty: '', tellUsAboutYou: '' }
  );
  const [fifthStepForm, updateFifthStepForm] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    { email: '', password: '', confirmPassword: '' }
  );

  const {
    form,
    setFormValues,
    formOptions,
    formValues,
    setLanguageError,
    setRegisterSuccess,
    setSaving,
  } = useRegister();

  const { currentLanguage } = useLanguage();
  const { activeTab, handleChangeTab, setActiveTab, setTabHeader, tabHeader } =
    useChangeTabs({
      handleSubmitForm,
      handleValidForm,
      setButtonText,
      setIsFormValid,
      setLanguageError,
    });
  const { t } = useTranslation();
  const { registerFieldsHelper, authUser: user } = useUser();

  async function savePhoto() {
    try {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        await MentorService.uploadAdvisorImageOnRegister({
          data: formData,
        });
        successHandler({ message: 'toast.pictureSaved' });
      } else {
        return;
      }
    } catch (error) {
      errorHandler({ error, t });
    }
  }

  const createMentor = (values) => {
    try {
      const mentor = ParserMentor.createMentor({ mentor: values });
      return mentor;
    } catch (error) {
      errorLogger({
        error,
        message: `[REGISTER][MENTOR] Error creating form mentor, erro: ${error.message}`,
      });
    }
  };

  async function saveMentor() {
    let values = {
      ...formValues,
      ...fourthStepForm,
    };

    const authGoogleUser = localStorage.getItem(CONTENTA_REGISTER_USER);
    if (!authGoogleUser?.sub) {
      values = {
        ...values,
        ...fifthStepForm,
      };
    }

    const formValuesDB = createMentor(values);

    setSaving(true);
    setButtonText('save');
    try {
      await MentorService.saveMentor(formValuesDB);
      setRegisterSuccess(true);
      savePhoto();
      setFormValues(formValuesDB);

      // pushEvent({
      //   event: 'conversion',
      //   send_to: `${TAG_MANAGER_ID}/_AU9CISzkpYYEK-g0asp`,
      // });

      localStorage.removeItem(CONTENTA_REGISTER_USER);
    } catch (error) {
      setActiveTab('5');
      errorHandler({ error, t });
      setRegisterSuccess(false);
      Sentry.captureException(
        new Error(
          `[REGISTER][MENTOR] Error saving mentor, erro: ${error.message}`
        ),
        {
          level: 'error',
          contexts: {
            formDataParsed: formValuesDB,
            formWithoutParsed: values,
          },
        }
      );
    }

    setSaving(false);
    setButtonText('save');
  }

  async function handleSubmitForm() {
    saveMentor();
  }

  function handleValidForm(isValid, clickedTab) {
    if (isValid) {
      setActiveTab(clickedTab || `${parseInt(activeTab, 10) + 1}`);
      setTabHeader({
        ...tabHeader,
        [`tab${activeTab}`]: (
          <span className="step-checked">{t(`guides.step${activeTab}`)}</span>
        ),
      });

      if (activeTab === '4') {
        setButtonText('save');
      }
    }
  }

  useEffect(() => {
    if (user?.email) {
      form.setFieldsValue({ email: user.email });
    }
  }, [user]);

  useEffect(() => {
    setTabHeader({
      tab1: <span>{t('guides.step1')}</span>,
      tab2: <span>{t('guides.step2')}</span>,
      tab3: <span>{t('guides.step3')}</span>,
      tab4: <span>{t('guides.step4')}</span>,
      tab5: <span>{t('guides.step5')}</span>,
    });
  }, [currentLanguage]);

  return (
    <>
      <Tabs defaultActiveKey="1" activeKey={activeTab}>
        <TabPane tab={tabHeader.tab1} key="1" animated>
          <Form {...formOptions}>
            <FirstStep setSelectedFile={setSelectedFile} />
          </Form>
        </TabPane>
        <TabPane tab={tabHeader.tab2} key="2" animated>
          <Form {...formOptions}>
            <SecondStep />
          </Form>
        </TabPane>
        <TabPane tab={tabHeader.tab3} key="3" animated>
          <Form {...formOptions}>
            <ThirdStep />
          </Form>
        </TabPane>
        <TabPane tab={tabHeader.tab4} key="4" animated>
          <Form {...formOptions}>
            <FourthStep
              updateFourthStepForm={updateFourthStepForm}
              fourthStepForm={fourthStepForm}
            />
          </Form>
        </TabPane>
        <TabPane tab={tabHeader.tab5} key="5" animated>
          <Form {...formOptions}>
            <FifthStep
              updateFifthStepForm={updateFifthStepForm}
              fifthStepForm={fifthStepForm}
            />
          </Form>
        </TabPane>
      </Tabs>

      <div className="tab-footer">
        <div className="tab-footer--actions">
          <ContentaButtonPrimaryOutline
            className="btn-primary"
            onClick={() =>
              handleChangeTab({
                activeTab: parseInt(activeTab, 10) - 1,
                isBacking: true,
              })
            }
            disabled={activeTab === '1'}
          >
            {t('common.back')}
          </ContentaButtonPrimaryOutline>
          <ContentaButtonPrimaryOutline
            className="btn-primary"
            onClick={() => handleChangeTab({ clickedTab: null })}
            disabled={
              !registerFieldsHelper.isAdult ||
              (activeTab === LAST_STEP &&
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0)
            }
          >
            {buttonText === 'save' ? t('common.save') : t('common.continue')}
          </ContentaButtonPrimaryOutline>
        </div>
        {activeTab === LAST_STEP && !isFormValid && (
          <Text type="danger">{t('guides.checkData')}</Text>
        )}
      </div>
    </>
  );
}

export default RegisterSteps;
